<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12">

                    <h4 class="text--secondary">
                        {{ $t('generic.lang_additionalImages') +' '+ additionalImages.length +'/'+ maxAdditionalImages}} 
                    </h4>
                    <v-divider />
                    <v-btn color="primary" v-if="maxAdditionalImages > additionalImages.length && !deleteLoading"
                        @click="showImageUploadDialog = true">
                        {{ $t('generic.lang_uploadImage') }}
                    </v-btn>
                </v-col>
                <v-col v-for="image in additionalImages" :key="image" class="d-flex child-flex" cols="2" sm="4" md="3">
                    <v-card flat tile>
                        <v-img :src="imageUrl(image)" aspect-ratio="1" class="grey lighten-2">
                            <template v-slot:placeholder>
                                <v-skeleton-loader type="image"></v-skeleton-loader>
                            </template>
                        </v-img>

                        <v-card-actions>
                            <v-btn @click="deleteImage(image)" :loading="deleteLoading" :disabled="deleteLoading"
                                color="error" text>
                                <v-icon>delete</v-icon> {{ $t('generic.lang_delete') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>


        <v-dialog persistent v-model="showImageUploadDialog" max-width="700">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title> {{ $t('generic.lang_uploadImage') }} </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog" :disabled="loading">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>
                    <Imagecropper v-if="showImageUploadDialog" ref="additionalImageCropper" v-model="image">
                    </Imagecropper>
                </v-card-text>

                <v-card-actions>
                    <v-btn text color="error" @click="closeDialog" :disabled="loading"> {{ $t('generic.lang_cancel') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="uploadImage" :loading="loading" :disabled="loading"> {{
                        $t('generic.lang_hochladen') }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Imagecropper from '@/components/common/imagecropper.vue';
import { ENDPOINTS } from '@/config/';
import { Events } from "@/plugins/events";


export default {
    name: "ItemAdditionalImagesComponent",
    components: {
        Imagecropper,
    },
    props: ['itemId'],
    data() {
        return {
            showImageUploadDialog: false,
            deleteLoading: false,
            loading: false,
            image: null,
            productImages: [],
            maxAdditionalImages: 4
        }
    },
    computed: {
        ...mapState(['api']),
        imageUrl() {
            return (image) => "https://img.3pos.de/unsafe/250x250/billsys_" + this.api.auth.posID + "/original/" + image;
        },
        additionalImages() {
            if(this.productImages && this.productImages.length > 1)
                return this.productImages.slice(1, this.productImages.length);
            return [];
        }
    },
    methods: {
        closeDialog() {
            this.image = null;
            this.showImageUploadDialog = false;
        },
        uploadImage() {
            this.loading = true;

            this.$refs.additionalImageCropper.cropImage();

            this.axios.post(ENDPOINTS.ERP.ITEM.ADDITIONALIMAGES.CREATE, {
                id: this.itemId,
                uploadImage: this.image
            }).then((res) => {
                if (res.data.success) {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_ActionSuccessful'),
                        color: "success",
                    });
                    this.getImages();
                    this.closeDialog();
                } else {
                    Events.$emit("showSnackbar", {
                        message: this.$t("generic.lang_errorOccurred"),
                        color: "error",
                    });
                }
            }).catch((err) => {
                Events.$emit("showSnackbar", {
                    message: this.$t("generic.lang_errorOccurred"),
                    color: "error",
                });
            }).finally(() => {
                this.loading = false;
            })
        },
        getImages() {
            this.loading = true;
            this.axios.post(ENDPOINTS.ERP.ITEM.ADDITIONALIMAGES.GET, {
                id: this.itemId,
            }).then((res) => {
                this.productImages = res.data.productImages;
            }).catch((err) => {
                Events.$emit("showSnackbar", {
                    message: this.$t("generic.lang_errorOccurred"),
                    color: "error",
                });
            }).finally(() => {
                this.loading = false;
            })
        },
        deleteImage(image) {
            this.$swal({
                title: this.$t('generic.lang_deleteImage'),
                text: this.$t("generic.lang_imageDeleteConfirm"),
                icon: "warning",
                cancelButtonText: this.$t('generic.lang_cancel'),
                confirmButtonText: this.$t('generic.lang_delete'),
                showCancelButton: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    this.deleteLoading = true;

                    this.axios.post(ENDPOINTS.ERP.ITEM.ADDITIONALIMAGES.DELETE, {
                        id: this.itemId,
                        imageUID: image
                    }).then((res) => {
                        if (res.data.success) {
                            Events.$emit("showSnackbar", {
                                message: this.$t("generic.lang_deletedSuccessfully"),
                                color: "success",
                            });
                            this.getImages();
                        } else {
                            Events.$emit("showSnackbar", {
                                message: this.$t("generic.lang_errorOccurred"),
                                color: "error",
                            });
                        }
                    }).catch((err) => {
                        Events.$emit("showSnackbar", {
                            message: this.$t("generic.lang_errorOccurred"),
                            color: "error",
                        });
                    }).finally(() => {
                        this.deleteLoading = false;
                    });
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            });
        }
    },
    mounted() {
        this.getImages();
    }
}

</script>