<template>
  <v-container class="px-0" fluid>
    <v-data-table :loading="this.loading" :items-per-page="-1" :items="this.customer_groups_prices"
                  :headers="this.headers">
      <template v-slot:item.purchase_price="{}">
        {{ itemPurchasePrice | currency}}
      </template>

      <template v-slot:item.original_price="{}">
        {{ originalSellPrice | currency }}
      </template>


      <template v-slot:item.net_price="{item}">
        {{ netPrice(item) | currency }}
      </template>

      <template v-slot:item.totalPrice="{item}">
        <v-text-field type="number" :loading="item.loading" :disabled="item.loading"
                      @change="updateCustomerGroupPrice(item)"
                      v-model="item.totalPrice" dense outlined
                      hide-details :suffix="$store.getters['settings/currencyData']('symbol')"/>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {mapState} from "vuex";
import {roundNumber} from "@/plugins/round";
import {Events} from "@/plugins/events";

export default {
  name: "ItemCustomerGroupPrices",
  props: {
    itemId: {
      type: [String, Number],
      required: true
    },
    itemPurchasePrice: {
      type: [String, Number],
      required: true
    },
    itemTax: {
      type: Number,
      default: 0
    },
    originalSellPrice: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      customer_groups_prices: []
    }
  },
  computed: {
    ...mapState([
      'settings',
    ]),
    netPrice() {
      return (group) => {
        return roundNumber(((parseFloat(group.totalPrice) / (1 + Number(this.itemTax) / 100)) * 100) / 100, this.roundingPlaces)
      }
    },
    roundingPlaces() {
      return Number(this.settings.settings.roundingPlaces) && Number(this.settings.settings.roundingPlaces) > 0 ? Number(this.settings.settings.roundingPlaces) : 2;
    },
    headers() {
      return [
        {
          text: this.$t('customers.lang_custGroup'),
          value: "nameCustomerGroup"
        },
        {
          text: this.$t('erp.lang_netPurchasePricePerUnit'),
          value: "purchase_price"
        },
        {
          text: this.$t('generic.lang_originalPrice'),
          value: "original_price"
        },
        {
          text: this.$t('erp.lang_ware_net'),
          value: "net_price"
        },
        {
          text: this.$t('erp.lang_groosSalesPrice'),
          value: "totalPrice"
        }
      ]
    }
  },
  methods: {
    getCustomerGroups() {
      this.loading = true;
      this.axios.post(ENDPOINTS.ERP.ITEM.CUSTOMER_GROUP_PRICES.PRICES_PER_ITEM, {
        itemId: this.itemId
      }).then((res) => {
        if (Object.prototype.hasOwnProperty.call(res.data, 'custGroupPrices'))
          this.customer_groups_prices = [...res.data.custGroupPrices];
        else
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    updateCustomerGroupPrice(group) {
      group.loading = true;
      this.$forceUpdate();

      this.axios.post(ENDPOINTS.ERP.ITEM.CUSTOMER_GROUP_PRICES.UPDATE, {
        itemId: this.itemId,
        customerGroupId: group.idCustomerGroup,
        totalPrice: group.totalPrice
      }).then((res) => {
        if (res.data.status === "SUCCESS") {
          this.getCustomerGroups();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        group.loading = false;
      })
    }
  },
  mounted() {
    this.getCustomerGroups();
  }
}
</script>

<style scoped>

</style>
