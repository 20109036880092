<template>
  <v-container fluid class="px-0">
    <v-row class="px-0">
      <v-col cols="12" class="text-right">
        <v-spacer/>
        <v-btn @click="open" color="success" depressed>
          {{ this.$t('generic.lang_create') }}
        </v-btn>
      </v-col>

      <v-col cols="12" class="mx-0">
        <Datatable
            :api-endpoint="ENDPOINTS.DATATABLES.ERP.SERIALNUMBERS"
            :datatable-headers="this.datatableHeaders"
            :elevation="0"
            :v-show="true"
            @deleteEntries="deleteEntries"
            :data="{
              itemId: this.$route.params.id
            }"
            item-key="uuid"
            @deleteEntry="deleteEntry"
            ref="serialNumbers"
            show-delete-buttons
            show-pdf
            show-select-checkbox
            no-excel-export
            :permissionDelete="this.$store.getters['permissions/checkPermission']('set_permissions')"
        />
      </v-col>
    </v-row>

    <!-- create serial numbers dialog -->
    <v-dialog max-width="700" v-model="create_dialog">
      <v-card>
        <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
          {{ $t('erp.lang_addNumbers') }}
        </v-card-title>

        <v-card-text class="pt-4">
          <v-form lazy-validation ref="form">
            <v-text-field autofocus v-model="numbers[i]" @keypress.enter="addElement" :rules="[v => !!v]" class="mt-2"
                          hide-details
                          append-icon="delete"
                          @click:append="deleteElement(i)"
                          :value="number"
                          :key="i"
                          outlined dense
                          :label="$t('erp.lang_serialnumber') + ' ' + (i + 1)"
                          v-for="(number , i) in numbers"/>

          </v-form>

          <div class="w-100 text-right">

            <v-spacer/>
            <v-btn class="mx-auto" @click="addElement" depressed color="success">
              <v-icon>add</v-icon>
            </v-btn>
          </div>
        </v-card-text>


        <v-card-actions :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-footer">
          <v-btn color="error" text @click="cancel">
            {{ this.$t('generic.lang_cancel') }}
          </v-btn>

          <v-spacer/>


          <v-btn :disabled="!(Array.isArray(this.numbers) && this.numbers.length > 0) || this.creating_loader"
                 :loading="this.creating_loader" depressed color="primary"
                 @click="create">
            {{ this.$t('generic.lang_create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {Events} from "@/plugins/events";
import {ENDPOINTS} from "@/config";
import Datatable from "@/components/datatable/Datatable";

export default {
  name: "ItemSerialNumbersComponent",
  components: {
    Datatable
  },
  data() {
    return {
      ENDPOINTS,
      create_dialog: false,
      numbers: [],
      creating_loader: false,
      datatableHeaders: [
        {
          text: "uuid",
          value: "uuid",
          hide: true
        },
        {
          text: this.$t('erp.lang_serialnumber'),
          value: "number",
        }
      ]
    }
  },
  methods: {
    deleteEntries(idsToDelete = []) {
      this.$swal({
        title: this.$t('erp.lang_ware_delete_sub'),
        text: this.$t('erp.lang_ware_delete_confirm'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(parseInt(this.id));
          }
          this.axios.post(ENDPOINTS.ERP.ITEM.SERIALNUMBERS.DELETE, {
            serialNumbers: idsToDelete,
            itemId: this.$route.params.id
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_itemSuccessfulDeleted'),
                color: "success"
              });

              this.$refs.serialNumbers.getDataFromApi();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    deleteEntry(entry) {
      this.deleteEntries([entry.uuid])
    },
    open() {
      this.numbers = [""];
      this.create_dialog = true;
    },
    cancel() {
      this.numbers = [];
      this.create_dialog = false
    },
    create() {
      this.creating_loader = true;
      this.axios.post(ENDPOINTS.ERP.ITEM.SERIALNUMBERS.CREATE, {
        serialNumbers: [...this.numbers],
        itemId: this.$route.params.id
      }).then((res) => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_actionSuccessful'),
            color: "success"
          });
          this.cancel();
          this.$refs.serialNumbers.getDataFromApi();
        } else if (res.data.status === false) {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.creating_loader = false;
      });
    },
    /**
     * delete element from creation form
     * @param index
     */
    deleteElement(index) {
      this.numbers.splice(index, 1)
    },
    addElement() {

      if (!this.$refs.form.validate()) return;
      if (!Array.isArray(this.numbers)) this.numbers = []

      this.numbers.push("")
    }
  }
}
</script>

<style scoped>

</style>
