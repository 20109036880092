<template>
  <v-container class="pr-5 ma-0" fluid>
    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-progress-linear v-if="loading" indeterminate/>
      </v-col>
      <v-col
          cols="12"
      >
        <v-switch v-model="wooCommerce.active" :label="$t('generic.lang_productVisibleInWooCommerce')"  :true-value="1" :false-value="0" />
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col
          cols="12"
          md="6"
      >
        <v-text-field
            v-model="wooCommerce.name"
            :label="$t('generic.lang_product_name')"
            required
            outlined
            dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col
          cols="12"
          md="6"
      >
        <v-text-field
            v-model="wooCommerce.price"
            :label="$t('generic.lang_priceInWooCommerce')"
            required
            outlined
            dense
            :suffix="$store.getters['settings/currencyData']('symbol')"
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          md="6"
      >
        <v-text-field
            v-model="wooCommerce.delivery"
            :label="$t('generic.lang_deliveryPeriod')"
            required
            outlined
            dense
            :suffix="$t('generic.lang_days')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0" >
      <v-col cols="12">
        <p>{{ $t('generic.lang_shortDescription') }} : </p>
        <quill-editor :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions"
                      @focus="showTouchKeyboard"
                      id="editor2"
                      output="html" v-model="wooCommerce.shortDescription" >

        </quill-editor>
      </v-col>
    </v-row>
    <v-row class="pt-15 ma-0" >
      <v-col cols="12">
        <p>{{ $t("generic.lang_description")}}: </p>
        <quill-editor :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions"
                      @focus="showTouchKeyboard"
                      id="editor1"
                      output="html" v-model="wooCommerce.description" >

        </quill-editor>
      </v-col>
    </v-row>
    <v-row class="pt-15 ma-0" >
      <v-col cols="12">
        <p>{{ $t('generic.lang_category') }} : </p>
        <v-select
            v-model="wooCommerce.selectCategories"
            :items="this.item.itemGroup"
            :label="$t('generic.lang_waregroup')"
            item-text="name"
            item-value="id"
            multiple
            outlined
        ></v-select>
      </v-col>
    </v-row>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
          :accept="hideTouchKeyboard"
          :cancel="hideTouchKeyboard"
          :defaultKeySet="touchKeyboard.keySet"
          :input="touchKeyboard.input"
          :layout="touchKeyboard.layout"
          :options="touchKeyboard.options"
          class="internalWidthExpanded"
          id="onScreenKeyboard"
          v-if="touchKeyboard.visible"
      />
    </div>
  </v-container>
</template>

<script>
import mixin from "../../../../mixins/KeyboardMixIns";
import {quillEditor} from 'vue-quill-editor';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
//components
import country from "../../../common/country";
import Template from "../../../settings/customerdisplay/Template";

export default {
  name: "WooCommerceComponent",
  components: {
    quillEditor,
    Template,
    country,
  },
  props: {
    // use v-model to set the component values found in the variable 'wooCommerce' in the data options, try to respect its (wooCommerce) data structure
    value:{

    },
    item:{

      /*
      # just update the comment when extending the passed item data to avoid conflicts #
      * required data for default fields are
      * - salePrice
      * - id
      *
      * */

      type:Object,
      require:true,
    },
    variations: Array
  },
  mixins: [mixin],
  data(){
    return {
      endpoint:'',
      token:'',
      loading:false,
      wooCommerce : {
        active: 0,
        name : "",
        description : "",
        shortDescription: "",
        price :this.item.salePrice,
        delivery :3,
        selectCategories: [],
      },
      select: ['Vuetify', 'Programming'],

      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'align': []}],
            [{'font': []}],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
          ],
        },
      },
    }
  },
  watch:{
    wooCommerce:{
      handler(newValue, oldValue){
        this.$emit('input',newValue);
      },
      deep:true,
    }
  },
  mounted() {
    //  //console.log(this.variations)
    if(this.value){
      Object.assign(this.wooCommerce,this.value);
    }
  },
}
</script>

<style scoped>
#editor1 {
  height: 200px;
  width: 100%;
}
#editor2 {
  height: 110px;
  width: 100%;
}
.v-expansion-panel-content__wrap, .v-expansion-panel-content {
  padding: 0 !important;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>
