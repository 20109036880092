<template>
  <v-container class="pt-8 pb-4" fluid>
    <v-row>
      <v-col class="py-0" cols="12">
        <Datatable :api-endpoint="ENDPOINTS.DATATABLES.ERP.ITEMSTORAGEINFO"
                   :custom-control-buttons="customControlButtons" :additional-get-params="['itemId',wareId]"
                   :datatable-headers="datatableHeaders" @downloadExcel="downloadExcel" no-excel-export :show-select-checkbox="false"
        ></Datatable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Datatable from "../../../datatable/Datatable";
import {ENDPOINTS} from "../../../../config";
import {Events} from "@/plugins/events";
import FileSaver from 'file-saver';


export default {
  name: "ItemStorageLogComponent",
  props: {
    wareId: {
      type: Number,
      require: true,
    }
  },
  components: {
    Datatable,
  },
  data() {
    return {
      ENDPOINTS,
      customControlButtons: [
        {
          title: 'Excel',
          handler: "downloadExcel",
          forceEnable:true,
          layout: {
            icon: "mdi-file-excel-outline",
            color: "success"
          }
        },
      ],
    }
  },
  computed: {
    datatableHeaders() {
      //Date Time, quantity before, quantity change, qty after, type of alteration, user id, cashier id, storage id
      return [
        {
          text: this.$t('generic.lang_date')+" / "+this.$t('generic.lang_time'),
          align: 'left',
          value: 'date',
          width: 80,
        },
        {text: this.$t('erp.lang_quantity')+' '+this.$t('generic.lang_before'), value: 'qtyBefore',},
        {text: this.$t('generic.lang_quantityChange'), value: 'qtyChange', },
        {text: this.$t('erp.lang_quantity')+' '+this.$t('generic.lang_after'), value: 'qtyAfter'},
        {text: this.$t('generic.lang_receipttype'), value: 'type'},
        {text: this.$t('settings.lang_userid'), value: 'userID'},
        {text: this.$t('generic.lang_cashierID'), value: 'cashierID'},
        {text: this.$t("erp.lang_storageID"), value: 'storageID'},
      ]
    },
  },
  methods: {
    downloadExcel() {
      this.axios.post(ENDPOINTS.ERP.ITEM.WAREINFO.STORAGELOGS.EXCEL,{itemId:this.wareId},
          {
            responseType: 'arraybuffer',
          }).then((res) => {
        //DOWNLOAD excel
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }), "storage_logs.xlsx");
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + '\n' + err.message,
          color: "error"
        });
      }).finally(() => {

      })
    },
  }
}
</script>

<style scoped>

</style>