<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col class="text-right" cols="12">
        <v-spacer/>

        <v-dialog max-width="700px" v-model="dialog">
          <template v-slot:activator="{ on }">
            <button @click="(edit = false) & (dialog = true)"
                    class="btn-shadow d-inline-flex align-items-center btn btn-success pl-1 mx-auto"
                    type="button"
                    v-on="on">
              <v-icon class="mr-1 white--text pa-0" icon="plus">add</v-icon>
              {{ $t('generic.lang_create') }}
            </button>
          </template>

          <v-card>
            <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
              {{ this.formTitle }}
            </v-card-title>
            <v-card-text class="pt-5">
              <v-form lazy-validation ref="form">
                <v-row>
                  <v-col sm="12" md="6">
                    <v-text-field
                      v-model="form.barcode"
                      :rules="[(v) => !!v, duplicatedEAN || $t('erp.lang_barcodeTaken')]"
                      @blur="checkEAN"
                      :loading="loadingCheck"
                      dense
                      outlined
                      :label="$t('erp.lang_ware_create_id')"
                    >
                      <template slot="append">
                        <div style="margin-top: -5px">
                          <font-awesome-icon
                            :icon="['fal', 'barcode']"
                            @click="GetWareId"
                            class="linkCursor"
                            size="2x"
                          />
                        </div>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col sm="12" md="6">
                    <v-text-field :rules="[v => !!v]" dense outlined v-model="form.itemName"
                                  :label="$t('erp.lang_posItemName')"/>
                  </v-col>


                  <v-col sm="12" md="6" v-if="useNetPriceForItemData">
                    <v-text-field :rules="[v => !!v]" dense outlined v-model="form.netSalePrice"
                                  :label="$t('erp.lang_netSellPrice')"/>
                  </v-col>

                  <v-col sm="12" md="6" v-else>
                    <v-text-field :rules="[v => !!v]" dense outlined v-model="form.salepriceTotal"
                                  :label="$t('erp.lang_groosSalesPrice')"/>
                  </v-col>

                  <v-col sm="12" md="6">
                    <v-text-field type="number" :rules="[v => !!v]" dense outlined v-model="form.decreaseStockMainItem" @input="autoCalcPricesByAmount"
                                  :label="$t('erp.lang_decraeseFromMainItemAddOnBarcode')"/>
                  </v-col>
                  <v-col cols="12">
                    <Imagecropper ref="addBarcodeImageCropper" v-model="form.productImageUpload"
                                  @update="form.imageUpdate = true"/>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>

            <v-card-actions :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-footer text-right">
              <v-spacer/>
              <v-btn :disabled="loading" @click="dialog = false" color="error">
                {{ $t('generic.lang_cancel') }}
              </v-btn>

              <v-btn v-if="!edit" @click="save" :loading="loading" :disabled="loading" color="primary">
                {{ $t('generic.lang_create') }}
              </v-btn>

              <v-btn v-else @click="save" :loading="loading" :disabled="loading" color="primary">
                {{ $t('generic.lang_edit') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col class="pa-0" cols="12">

        <Datatable
            :api-endpoint="ENDPOINTS.DATATABLES.ERP.ITEMBARCODES"
            :data="this.params"
            :datatable-headers="datatableHeaders"
            :elevation="0"
            :excel-columns="excelColumns"
            :v-show="true"
            @deleteEntries="deleteEntries"
            @deleteEntry="deleteEntry"
            @editEntry="editEntry"
            excel-file-name="additionalBarcode"
            ref="additionalBarcode"
            show-delete-buttons
            show-edit-buttons
        />
      </v-col>

    </v-row>


  </v-container>
</template>

<script>
import Datatable from "@/components/datatable/Datatable";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import Imagecropper from "../../../common/imagecropper";
import {roundNumber} from "@/plugins/round";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBarcode } from "@fortawesome/pro-light-svg-icons";

library.add(
	faBarcode,
);

export default {
  name: "EditItemAdditionalBarcode",
  components: {
    Imagecropper,
    Datatable,
    "font-awesome-icon": FontAwesomeIcon,
  },
  props: {
    itemName: String,
    itemSellPrice: {
      type: [String, Number],
      required: true
    },
    itemNetPrice: {
      type: [String, Number],
      default: 0
    }
  },
  data: () => {
    return {
      dialog: false,
      loading: false,
      defaultItem: {
        barcode: "",
        itemName: "",
        salepriceTotal: "",
        netSalePrice: "",
        decreaseStockMainItem: "",
        mainItemId: '',
        taxId: '',
        itemId: '',
        imageUpdate: false,
        deleteImage: false,
        productImageUpload: null
      },
      edit: false,
      ENDPOINTS,
      form: {
        barcode: "",
        itemName: "",
        salepriceTotal: "",
        decreaseStockMainItem: "",
        netSalePrice: "",
        mainItemId: '',
        taxId: '',
        itemId: '',
        imageUpdate: false,
        deleteImage: false,
        productImageUpload: null
      },
      duplicatedEAN: false,
      loadingCheck: false,
    }
  },
  computed: {
    useNetPriceForItemData() {
      return Number(this.$store.getters['settings/getSettingValue']("useNetPriceForItemData")) === 1;
    },
    isAutoCalcPriceByAmount(){
      return Number(this.$store.getters['settings/getSettingValue']("useNetPriceForItemData")) === 1;
    },
    mainItemId: function () {
      return this.$route.params.id;
    },
    params: function () {
      return {
        mainItemId: this.$route.params.id
      }
    },
    formTitle() {
      return this.edit ? this.$t('generic.lang_edit') + ' ' + this.$t('erp.lang_additionalBarcodes') : this.$t('generic.lang_create') + ' ' + this.$t('erp.lang_additionalBarcodes')
    },
    datatableHeaders: function () {
      return [
        {text: this.$t('erp.lang_ware_create_id'), value: "barcode"},
        {
          text: this.$t("erp.lang_posItemName"),
          value: "itemName",
          sortable: false
        },
        {text: this.$t("erp.lang_groosSalesPrice"), value: "salepriceTotal", sortable: false},
        {text: this.$t("erp.lang_decraeseFromMainItemAddOnBarcode"), value: "decreaseStockMainItem"},
        {text: 'mainItemId', value: "mainItemId", hide: true},
        {text: 'taxId', value: "taxId", hide: true},
        {text: 'itemId', value: "id", hide: true},

      ];
    },
    excelColumns: function () {
      return [
        {label: this.$t('erp.lang_ware_create_id'), field: "id"},
        {
          label: this.$t("erp.lang_posItemName"),
          value: "itemName",
        },
        {label: this.$t("erp.lang_groosSalesPrice"), field: "salepriceTotal", sortable: false},
        {label: this.$t("erp.lang_decraeseFromMainItemAddOnBarcode"), field: "decreaseStockMainItem"}
      ];
    }
  },
  watch: {
    dialog(val) {
      if (val && !this.edit) {
        this.form.itemName = this.itemName
      }
      
      if (!val) {
        this.close()
      }
    }
  },
  mounted() {
    this.autoCalcPricesByAmount();
  },
  methods: {
    autoCalcPricesByAmount(){
      if(!this.isAutoCalcPriceByAmount)
        return;

      let amount = Number(this.form.decreaseStockMainItem)?Number(this.form.decreaseStockMainItem):1;
      let itemSalePrice = Number(this.itemSellPrice)?Number(this.itemSellPrice):0;
      let itemNetPrice = Number(this.itemNetPrice)?Number(this.itemNetPrice):0;
      this.form.salepriceTotal = roundNumber(itemSalePrice * amount, 2);
      this.form.netSalePrice = roundNumber(itemNetPrice * amount, 2);
    },
    checkEAN() {
      this.loadingCheck = true;
      this.axios
        .post(ENDPOINTS.ERP.ITEM.BARCODES.CHECKDUPLICATEEAN, {
          itemId: this.form.id,
          barcode: this.form.barcode,
        })
        .then((res) => {
          this.duplicatedEAN = res.data.success;
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loadingCheck = false;
        });
    },
    GetWareId() {
      this.axios
        .post(ENDPOINTS.ERP.INVENTORYITEM.EAN.CREATE)
        .then((res) => {
          if (res.status === 200) {
            this.form.barcode = res.data && res.data != null && res.data.length > 0
                ? res.data[0]
                : this.form.barcode;
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        });
    },
    save() {

      if (!this.$refs.form.validate()) return;

      if (this.$refs["addBarcodeImageCropper"])
        this.$refs.addBarcodeImageCropper.cropImage();

      this.form.productImageUpload = this.form.imageUpdate ? this.form.productImageUpload : '';
      this.form.deleteImage = this.form.productImageUpload ? 0 : 1;

      const url = this.edit ? ENDPOINTS.ERP.ITEM.BARCODES.UPDATE : ENDPOINTS.ERP.ITEM.BARCODES.CREATE;
      let formData = new FormData();

      for (let elt in this.form)
        formData.append(elt, this.form[elt])
      formData.append('mainItemId', this.mainItemId);

      this.loading = true;
      this.axios.post(url, formData).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });

          this.$refs.additionalBarcode.getDataFromApi();
          this.$refs.additionalBarcode.resetSelectedRows();
          this.$refs.form.reset();
          this.close();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    getData(id) {
      this.loading = true;
      this.axios
          .post(ENDPOINTS.ERP.EDITITEM.GET, {
            editItemID: id,
          })
          .then((res) => {
            if (res.status === 200) {
              this.form.netSalePrice =
                  res.data.formfillData.textFields.ware_sellpriceNet;
              this.form.productImageUpload = res.data.imageData[1];
              this.form.imageUpdate = false;
              this.form.deleteImage = false;
            }
          })
          .catch((e) => {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }).finally(() => {
        this.loading = false;
      })
    },
    delete(idsToDelete) {
      this.$swal({
        title: this.$t("erp.lang_ware_delete_confirm"),
        text: this.$t("erp.lang_ware_delete_confirm"),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios.post(ENDPOINTS.ERP.ITEM.BARCODES.DELETE, {
            ids: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_success"),
                color: "success"
              });


              this.$refs.additionalBarcode.getDataFromApi();
              this.$refs.additionalBarcode.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        }
      });
    },
    deleteEntry(entry) {
      this.delete([entry.id])
    },
    deleteEntries(ids) {
      this.delete(ids)
    },
    editEntry(entry) {
      this.form = Object.assign({}, entry);
      this.form.barcode = entry.barcode;
      this.form.oldBarcode = entry.barcode;
      this.edit = true;
      this.dialog = true;
      this.getData(this.form.id)
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.form = Object.assign({}, this.defaultItem)
        this.edit = false
      })
    },
  }
}
</script>

<style scoped>

</style>
