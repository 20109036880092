<template>
  <v-container class="pa-md-12 pa-lg-12 pa-xl-12 pa-sm-6" fluid>
    <v-row>
      <v-col class="py-0" cols="12">
        <v-select
          v-model="form.unit"
          :items="units"
          dense
          outlined
          :label="$t('erp.lang_nutritionUnit')"
        />
      </v-col>

      <!--start: colories-->
      <v-col class="py-0" cols="12" sm="6">
        <v-text-field
          v-model="form.calories_kj"
          type="number"
          :min="0"
          suffix="kj"
          @change="kj_To_kcal"
          dense
          outlined
          :label="$t('erp.lang_nutritionKJCal')"
        />
      </v-col>

      <v-col class="py-0" cols="12" sm="6">
        <v-text-field
          v-model="form.calories_kcal"
          type="number"
          :min="0"
          suffix="kcal"
          @change="kcal_to_kj"
          dense
          outlined
          :label="$t('erp.lang_nutritionKcalCal')"
        />
      </v-col>
      <!--end: colories-->

      <!--start: Fat-->
      <v-col class="py-0" cols="12" sm="6">
        <v-text-field
          v-model="form.fat"
          type="number"
          :min="0"
          suffix="g"
          dense
          outlined
          :label="$t('erp.lang_nutritionFatInG')"
        />
      </v-col>

      <v-col class="py-0" cols="12" sm="6">
        <v-text-field
          v-model="form.saturated_fat"
          type="number"
          :min="0"
          suffix="g"
          dense
          outlined
          :label="$t('erp.lang_nutritionSaturatedFatInG')"
        />
      </v-col>
      <!--end: Fat-->

      <v-col class="py-0" cols="12" sm="6">
        <v-text-field
          v-model="form.carbohydrate"
          type="number"
          :min="0"
          suffix="g"
          dense
          outlined
          :label="$t('erp.lang_nutritionCarbohydratesInG')"
        />
      </v-col>

      <v-col class="py-0" cols="12" sm="6">
        <v-text-field
          v-model="form.sugars"
          type="number"
          :min="0"
          suffix="g"
          dense
          outlined
          :label="$t('erp.lang_nutritionSugarInG')"
        />
      </v-col>

      <v-col class="py-0" cols="12" sm="6">
        <v-text-field
          v-model="form.protein"
          type="number"
          :min="0"
          suffix="g"
          dense
          outlined
          :label="$t('erp.lang_nutritionProteinInG')"
        />
      </v-col>

      <v-col class="py-0" cols="12" sm="6">
        <v-text-field
          v-model="form.salt"
          type="number"
          :min="0"
          suffix="g"
          dense
          outlined
          :label="$t('erp.lang_nutritionSaltInG')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NutritionComponent",
  props: {
    value: {
      type: Object,
      default: () => ({
        unit: "",
        calories_kj: "",
        calories_kcal: "",
        fat: "",
        saturated_fat: "",
        carbohydrate: "",
        sugars: "",
        protein: "",
        salt: "",
      }),
    },
  },
  data() {
    return {
      units: ["100g", "100ml"],
    };
  },
  methods: {
    kj_To_kcal(val) {
      this.form.calories_kcal = parseFloat(parseFloat(val) * 0.2388).toFixed(2);
    },
    kcal_to_kj(val) {
      this.form.calories_kj = parseFloat(parseFloat(val) * 4.1868).toFixed(2);
    },
  },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
