<template>
  <div>


    <page-title :heading="$t('erp.lang_nav_wareedit')" :icon="icon" :subheading="this.itemname" show-previous-button />
      <div class="app-main__inner">
        <EditItem v-model="itemname"/>
      </div>
  </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import EditItem from "../../../components/erp/baseData/EditItem.vue";

export default {
  components: {
    PageTitle,
    EditItem
  },
  props: ["id"],
  data: () => ({
    itemname: "",
    icon: 'pe-7s-plugin icon-gradient bg-tempting-azure',
  }),
}
</script>