<template>
	<div>
		<div style="text-align: center" v-if="loading">
			<v-progress-circular color="primary" indeterminate size="50"></v-progress-circular>
		</div>

		<div
			:style="this.$vuetify.theme.dark ? 'background-color: #1E1E1E;' : ''"
			class="mb-3 card"
			v-if="!loading"
		>
			<div>
				<v-form lazy-validation ref="form">
					<v-tabs v-model="tab">
						<v-tab key="baseData">
							{{ $t("erp.lang_baseData") }}
						</v-tab>

						<v-tab v-if="this.isTranslationEnabled" key="translation">
							{{ $t("erp.lang_translation") }}
						</v-tab>

						<v-tab key="onlineShop" v-if="this.$store.getters['permissions/checkModule'](48)"> Epages </v-tab>
						<v-tab key="wooCommerce" v-if="this.$store.getters['permissions/checkModule'](63) || this.$store.getters['permissions/checkModule'](77)">
							WooCommerce
						</v-tab>
						<v-tab key="pricesize" v-if="this.$store.getters['permissions/checkModule'](24)"
							>{{ $t("erp.langPriceSizeMatrix") }}
						</v-tab>
						<v-tab key="picture">{{ $t("erp.lang_productImage") }}</v-tab>
						<v-tab key="wareattributes">{{ $t("erp.lang_warecreate_wareattr") }} </v-tab>
						<v-tab key="extras" v-if="this.$store.getters['permissions/checkModule'](19)">
							{{ $t("erp.lang_extras") }}
						</v-tab>

						<v-tab key="ingredient" v-if="this.$store.getters['permissions/checkModule'](20)">
							{{ $t("erp.lang_nav_recipe") }}
						</v-tab>

						<v-tab key="fibu">{{ $t("accounting.lang_fibuKonten") }}</v-tab>
						<v-tab key="additional_barcode" v-if="this.$store.getters['permissions/checkModule'](42)">
							{{ $t("erp.lang_additionalBarcodes") }}
						</v-tab>

						<v-tab key="customer_group_prices" v-if="this.$store.getters['permissions/checkModule'](0)">
							{{ $t("erp.lang_customer_group_prices") }}
						</v-tab>

						<v-tab key="serial_numbers" v-if="this.$store.getters['permissions/checkModule'](53)">
							{{ $t("erp.lang_serialnumber") }}
						</v-tab>

						<v-tab key="variations" v-if="this.$store.getters['permissions/checkModule'](33)"
							>{{ $t("erp.lang_wareVariation") }}
						</v-tab>

						<v-tab key="controlsystem">{{ $t("erp.lang_warecreate_waremanage") }} </v-tab>

						<v-tab
							key="nutrition"
							v-if="
								this.$store.getters['permissions/checkModule'](54) ||
								this.$store.getters['permissions/checkModule'](39)
							"
						>
							{{ $t("erp.lang_nutritionTable") }}
						</v-tab>

						<v-tab key="storagelog">
							{{ $t("erp.lang_storageHistory") }}
						</v-tab>

						<v-tabs-items touchless v-model="tab">
							<v-tab-item key="baseData">
								<v-container fluid>
									<v-layout wrap>
										<v-flex md5 xs12>
											<v-text-field
												:data-layout="KEYBOARD.KEYSETS.NORMAL"
												:label="$t('erp.lang_ware_create_id')"
												:rules="[(v) => !!v, this.duplicatedEAN || $t('erp.lang_barcodeTaken')]"
												@focus="showTouchKeyboard"
												autocomplete="off"
												outlined
												v-model="itemEAN"
												@blur="checkEAN"
												required
												:loading="this.loadingCheck"
											>
												<template slot="append">
													<div style="margin-top: -5px">
														<font-awesome-icon
															:icon="['fal', 'barcode']"
															@click="GetWareId"
															class="linkCursor"
															size="2x"
														/>
													</div>
												</template>
											</v-text-field>

											<v-text-field
												:data-layout="KEYBOARD.KEYSETS.NORMAL"
												:label="$t('erp.internal_wareNo')"
												@focus="showTouchKeyboard"
												autocomplete="off"
												outlined
												@change="checkInternID"
												@blur="checkInternID"
												:loading="loadingCheckInternID"
												:rules="[this.duplicatedInternID || $t('erp.lang_duplicatedInternalId')]"
												v-model="itemInternID"
											>
											</v-text-field>
											<v-text-field
												:data-layout="KEYBOARD.KEYSETS.NORMAL"
												:label="$t('erp.lang_posItemName')"
												:rules="[(v) => !!v]"
												@focus="showTouchKeyboard"
												autocomplete="off"
												outlined
												v-model="itemName"
											>
											</v-text-field>
											<v-select
												:items="itemGroupNameSelect"
												:label="$t('generic.lang_waregroup')"
												item-text="name"
												item-value="id"
												outlined
												v-model="itemGroupName"
											></v-select>

											<v-text-field
												:data-layout="KEYBOARD.KEYSETS.NORMAL"
												:label="$t('erp.lang_itemNameTranslation')"
												@focus="showTouchKeyboard"
												autocomplete="off"
												outlined
												v-model="itemNameTranslation"
											>
											</v-text-field>

											<v-text-field
												:data-layout="KEYBOARD.KEYSETS.NORMAL"
												:label="$t('erp.lang_itemNameOnOrderbon')"
												@focus="showTouchKeyboard"
												autocomplete="off"
												outlined
												v-model="itemOrderingName"
												v-if="this.$store.getters['permissions/checkModule'](2)"
											>
											</v-text-field>

											<v-switch
												:label="$t('erp.lang_dishOfTheDayControl')"
												v-model="isDailyGoods"
												v-if="this.$store.getters['permissions/checkModule'](2)"
												hide-details
												:true-value="1"
												:false-value="0"
												:loading="loadingIsDailyGoods"
												@change="updateIsDailyGoodsStatus"
											>
											</v-switch>
											<div class="mt-4" v-if="this.$store.getters['permissions/checkModule'](61)">
												<strong> {{ $t("settings.lang_navScaleSettings") }}:</strong>
												<v-switch
													:label="$t('erp.lang_enableWeighting')"
													v-model="enableWeighting"
													hide-details
													:true-value="1"
													:false-value="0"
												>
												</v-switch>
												<v-switch
													v-if="enableWeighting"
													:label="$t('erp.lang_enableTara')"
													v-model="enableTara"
													:true-value="1"
													:false-value="0"
												>
												</v-switch>
												<v-text-field
													v-if="enableTara && enableWeighting"
													:data-layout="KEYBOARD.KEYSETS.NUMERIC"
													:label="$t('erp.lang_taraInKg')"
													@focus="showTouchKeyboard"
													autocomplete="off"
													suffix="Kg"
													type="text"
													@keypress="decimalNumbers($event, tara)"
													outlined
													v-model="tara"
													:rules="[(v) => !!v]"
												>
												</v-text-field>
											</div>
										</v-flex>
										<v-flex md1 xs0></v-flex>
										<v-flex md6 xs12>
											<v-text-field
												:data-layout="KEYBOARD.KEYSETS.NUMERIC"
												:label="$t('erp.lang_netPurchasePricePerUnit')"
												@focus="showTouchKeyboard"
												autocomplete="off"
												outlined
												type="text"
												@keypress="decimalNumbers($event, itemPurchasePrice)"
												v-model="itemPurchasePrice"
											>
											</v-text-field>

											<v-row>
												<v-col cols="6">
													<v-text-field
														v-if="useNetPriceForItemData"
														:data-layout="KEYBOARD.KEYSETS.NUMERIC"
														:label="$t('erp.lang_netSellPrice')"
														@focus="showTouchKeyboard"
														autocomplete="off"
														outlined
														type="text"
														@keyup="calculMargin('net')"
														@keypress="decimalNumbers($event, netSalePrice)"
														v-model="netSalePrice"
													>
													</v-text-field>

													<v-text-field
														v-else
														:data-layout="KEYBOARD.KEYSETS.NUMERIC"
														:label="$t('erp.lang_ware_create_sprice') + ' ' + $t('erp.lang_gross')"
														:rules="[(v) => !!v]"
														@focus="showTouchKeyboard"
														autocomplete="off"
														outlined
														type="text"
														@keyup="calculMargin('gross')"
														@keypress="decimalNumbers($event, itemSalePrice)"
														v-model="itemSalePrice"
													>
													</v-text-field>
												</v-col>

												<v-col cols="6">
													<v-text-field
														v-model="percentageMargin"
														:data-layout="KEYBOARD.KEYSETS.NUMERIC"
														@focus="showTouchKeyboard"
														@keydown="checkNegativity"
														@keyup="calculSalePriceWithMargin"
														:disabled="itemPurchasePrice === '' || itemPurchasePrice === null"
														:label="$t('erp.lang_marginOfTheItemInPercent')"
														autocomplete="off"
														outlined
														suffix="%"
														type="number"
													></v-text-field>
												</v-col>
											</v-row>

											<v-select
												:items="filteredUnits"
												item-text="unit_name"
												item-value="id"
												:label="$t('erp.lang_purchaseUnit')"
												outlined
												v-model="unitofPurchaseID"
											>
											</v-select>

											<v-select
												:items="filteredUnits"
												item-text="unit_name"
												item-value="id"
												:label="$t('erp.lang_salesunit')"
												outlined
												v-model="unitID"
											>
											</v-select>
											<v-text-field
												:data-layout="KEYBOARD.KEYSETS.NORMAL"
												:label="$t('erp.lang_saleUnitValue')"
												@focus="showTouchKeyboard"
												autocomplete="off"
												outlined
												required
												v-model="unitValue"
												min="1"
												type="number"
											>
											</v-text-field>

											<v-row>
												<v-col cols="12" v-if="this.$store.getters['permissions/checkModule'](2)">
													<v-row dense>
														<v-col cols="8" align-self="center">
															<font-awesome-icon
																v-if="this.$store.getters['permissions/checkModule'](2)"
																style="margin-right: 10px; float: left"
																:icon="['fal', 'person-carry']"
																size="3x"
															/>
															<v-switch
																v-if="this.$store.getters['permissions/checkModule'](2)"
																v-model="itemDifferentTaxTakeAway"
																hide-details
																:label="$t('settings.lang_warecreate_taxsettingouterhouse')"
															>
															</v-switch>
														</v-col>
														<v-col
															cols="4"
															align-self="center"
															v-if="this.$store.getters['permissions/checkModule'](28)"
														>
															<v-checkbox :label="$t('erp.lang_itemIsMenu')" v-model="isMenu" hide-details />
														</v-col>
													</v-row>
												</v-col>

												<v-col cols="12" class="my-4" v-if="isMenu && itemDifferentTaxTakeAway">
													<v-expansion-panels class="border" style="border-color: grey !important">
														<v-expansion-panel>
															<v-expansion-panel-header>
																{{ $t("erp.lang_menuTaxRules") }}
															</v-expansion-panel-header>
															<v-divider class="ma-0" />
															<v-expansion-panel-content>
																<v-card>
																	<v-card-text class="px-2">
																		<v-row v-for="(taxRule, key) in taxRules" :key="key">
																			<template>
																				<v-col cols="6">
																					<v-text-field
																						:data-layout="KEYBOARD.KEYSETS.NORMAL"
																						:label="$t('generic.lang_ofWhichInPercent')"
																						@focus="showTouchKeyboard"
																						autocomplete="off"
																						outlined
																						required
																						v-model="taxRule.rate"
																						:rules="[rules.required]"
																						:error="validTax"
																						:error-messages="validTaxMsg"
																						@keyup="taxRulesInput(taxRule)"
																						@keydown.enter="reset(taxRule)"
																						@blur="reset(taxRule)"
																						min="0"
																						type="number"
																					/>
																				</v-col>
																				<v-col cols="6">
																					<v-text-field
																						:label="$t('erp.lang_tax_value')"
																						@focus="showTouchKeyboard"
																						autocomplete="off"
																						readonly
																						outlined
																						required
																						:value="taxRule.name"
																					/>
																				</v-col>
																			</template>
																		</v-row>
																	</v-card-text>
																</v-card>
															</v-expansion-panel-content>
														</v-expansion-panel>
													</v-expansion-panels>
												</v-col>
												<v-col cols="12">
													<v-select
														:items="itemTaxSelect"
														:label="$t('erp.lang_tax_value')"
														:rules="[(v) => !!v]"
														item-text="name"
														item-value="id"
														outlined
														v-model="itemTax"
													></v-select>
												</v-col>
											</v-row>

											<v-select
												:items="itemDepositSelect"
												:label="$t('erp.lang_warecreate_pfand')"
												item-text="name"
												item-value="id"
												outlined
												v-if="this.$store.getters['permissions/checkModule'](7)"
												v-model="itemDeposit"
											></v-select>

											<v-select
												:items="itemProducerSelect"
												:label="$t('erp.lang_ware_producer_name')"
												item-text="name"
												item-value="id"
												outlined
												v-model="itemProducer"
											></v-select>
										</v-flex>
									</v-layout>
									<v-layout>
										<v-flex md12 xs12>
											<v-textarea
												:label="$t('erp.lang_ware_create_desc')"
												name="itemDescription"
												outlined
												v-model="itemDescription"
												value=""
											></v-textarea>
										</v-flex>
									</v-layout>
									<v-layout wrap>
										<v-flex md5 xs12>
											<v-select
												:items="itemOrderbonIDSelector"
												:label="$t('settings.langOrderbonPrinter')"
												item-text="name"
												item-value="id"
												outlined
												v-if="this.$store.getters['permissions/checkModule'](2)"
												v-model="itemOrderbonID"
											></v-select>

											<v-text-field
												:data-layout="KEYBOARD.KEYSETS.NORMAL"
												:label="$t('erp.lang_zubereitungsZeitMin')"
												@focus="showTouchKeyboard"
												autocomplete="off"
												outlined
												type="number"
												v-if="this.$store.getters['permissions/checkModule'](2)"
												v-model="itemPrepartionTime"
											>
											</v-text-field>

											<fontawesome-picker
												:fieldLabel="$t('erp.lang_chooseItemIcon')"
												:value="selectedIcon"
												v-model="selectedIcon"
											></fontawesome-picker>
										</v-flex>
										<v-flex md1 xs0></v-flex>
										<v-flex md6 xs12>
											<strong>{{ $t("erp.lang_colourofWaregroup") }}:</strong>
											<v-color-picker
												v-model="itemColorPicker"
												mode="hexa"
												show-swatches
												hide-mode-switch
												hide-canvas
												elevation="1"
												width="100%"
												class="color-swatcher-without-transparency mx-auto my-8"
											></v-color-picker>

											<strong>{{ $t("erp.mealType") }}</strong>
											<br />

											<v-radio-group row v-model="itemTypeChooser">
												<v-radio :key="0" :value="1" v-if="this.$store.getters['permissions/checkModule'](2)">
													<template slot="label">
														<div>
															<font-awesome-icon :icon="['fal', 'cocktail']" size="3x" />
															<p>{{ $t("generic.lang_beverage") }}</p>
														</div>
													</template>
												</v-radio>

												<v-radio :key="1" :value="2" v-if="this.$store.getters['permissions/checkModule'](2)">
													<template slot="label">
														<div>
															<font-awesome-icon :icon="['fal', 'meat']" size="3x" />
															<p>{{ $t("generic.lang_food") }}</p>
														</div>
													</template>
												</v-radio>

												<v-radio :key="2" :label="`Radio`" :value="3">
													<template slot="label">
														<div>
															<font-awesome-icon :icon="['fal', 'gifts']" size="3x" />
															<p>{{ $t("generic.lang_item") }}</p>
														</div>
													</template>
												</v-radio>
												<v-radio :key="3" :label="`Radio`" :value="4">
													<template slot="label">
														<div>
															<font-awesome-icon :icon="['fal', 'concierge-bell']" size="3x" />
															<p>{{ $t("generic.lang_service") }}</p>
														</div>
													</template>
												</v-radio>
											</v-radio-group>
										</v-flex>
									</v-layout>
									<v-layout wrap>
										<v-flex md4 xs12>
											<v-checkbox
												:hide-details="true"
												:label="$t('erp.lang_warecreate_priceinputauto')"
												autocomplete="off"
												style="margin-top: 0px !important"
												v-model="openPriceInput"
											></v-checkbox>
											<v-checkbox
												:hide-details="true"
												:label="$t('erp.lang_wareWeightQTYAutoOpen')"
												autocomplete="off"
												style="margin-top: 0px !important"
												v-model="openWeightQTYInput"
											></v-checkbox>

											<v-checkbox
												:hide-details="true"
												:label="$t('erp.lang_ware_saisonalware')"
												autocomplete="off"
												style="margin-top: 0px !important"
												v-model="saisonalItem"
											></v-checkbox>

											<v-checkbox
												:hide-details="true"
												:label="$t('settings.lang_settings_activ')"
												autocomplete="off"
												style="margin-top: 0px !important"
												v-model="itemIsActive"
											></v-checkbox>

											<v-checkbox
												:hide-details="true"
												:label="$t('erp.chargeNoRequired')"
												autocomplete="off"
												style="margin-top: 0px !important"
												v-model="chargeNoRequired"
											></v-checkbox>
											<v-checkbox
												:hide-details="true"
												:label="$t('erp.mhdRequired')"
												autocomplete="off"
												style="margin-top: 0px !important"
												v-model="mhdRequired"
											></v-checkbox>

											<v-checkbox
												v-if="this.$store.getters['permissions/checkModule'](53)"
												:hide-details="true"
												:label="$t('erp.lang_askForSerialNumbers')"
												autocomplete="off"
												:value="1"
												style="margin-top: 0px !important"
												v-model="serialNumberActive"
											></v-checkbox>
										</v-flex>

										<v-flex md4 xs12>
											<v-checkbox
												:hide-details="true"
												:label="$t('erp.lang_warecreate_itemnameask')"
												autocomplete="off"
												style="margin-top: 0px !important"
												v-model="openItemNameChange"
											></v-checkbox>
											<v-checkbox
												:hide-details="true"
												:label="$t('erp.lang_isService')"
												autocomplete="off"
												style="margin-top: 0px !important"
												v-model="itemIsService"
											></v-checkbox>
											<v-checkbox
												:hide-details="true"
												:label="$t('erp.lang_includeItemProjectReport')"
												autocomplete="off"
												style="margin-top: 0px !important"
												v-model="includeItemIntoProjectReport"
											></v-checkbox>
											<v-checkbox
												:hide-details="true"
												:label="$t('erp.lang_pagerNumberREQ')"
												autocomplete="off"
												style="margin-top: 0px !important"
												:false-value="0"
												:true-value="1"
												v-model="pagerNoReq"
											></v-checkbox>
											<v-checkbox
												:hide-details="true"
												:label="$t('erp.lang_itemIsNotDiscountable')"
												autocomplete="off"
												style="margin-top: 0px !important"
												:false-value="0"
												:true-value="1"
												v-model="itemNotDiscountable"
											></v-checkbox>
											<v-checkbox
												:hide-details="true"
												:label="$t('erp.lang_itemPriceCannotChange')"
												autocomplete="off"
												style="margin-top: 0px !important"
												:false-value="0"
												:true-value="1"
												v-model="priceCannotChange"
											></v-checkbox>
										</v-flex>
										<v-flex md4 xs12>
											<v-checkbox
												:hide-details="true"
												:label="$t('erp.lang_auszahlArtikel')"
												autocomplete="off"
												style="margin-top: 0px !important"
												v-model="payOutItem"
											></v-checkbox>
											<v-checkbox
												:hide-details="true"
												:label="$t('erp.lang_bookingNumberREQ')"
												autocomplete="off"
												style="margin-top: 0px !important"
												v-model="bookingNoReq"
											></v-checkbox>

											<v-text-field
												v-if="bookingNoReq"
												v-model="bookingNoRegExp"
												:data-layout="KEYBOARD.KEYSETS.NORMAL"
												@focus="showTouchKeyboard"
												:label="$t('erp.lang_optionalBookingNoValidationRegex')"
												autocomplete="off"
												outlined
												class="mt-4"
											></v-text-field>

											<v-checkbox
												:hide-details="true"
												:label="$t('erp.lang_includeIntoStats')"
												autocomplete="off"
												style="margin-top: 0px !important"
												v-model="includeInStatistics"
											></v-checkbox>
											<v-radio-group v-model="ageVerification">
												<v-radio
													key="1"
													:label="
														$t('erp.lang_ageVerification') + ' ' + $t('settings.lang_settings_deactiviert')
													"
													value="0"
												></v-radio>
												<v-radio
													key="2"
													:label="$t('erp.lang_ageVerification') + ' 16 ' + $t('generic.lang_yearsOfAge')"
													value="16"
												></v-radio>
												<v-radio
													key="3"
													:label="$t('erp.lang_ageVerification') + ' 18 ' + $t('generic.lang_yearsOfAge')"
													value="18"
												></v-radio>
											</v-radio-group>
										</v-flex>
										<v-flex v-if="this.$store.getters['permissions/checkModule'](27)" md4 xs12>
											<p>{{ $t("generic.lang_barcodeWeightPriceMode") }}</p>
											<v-btn-toggle class="mb-1" active-class="success" v-model="barcodeWeightPriceMode">
												<v-btn :value="0" class="ma-0">
													{{ $t("generic.lang_standard") }}
												</v-btn>

												<v-btn :value="1" class="ma-0">
													{{ $t("generic.lang_cashier_price") }}
												</v-btn>

												<v-btn :value="2" class="ma-0">
													{{ $t("generic.lang_weightBarcode") }}
												</v-btn>
											</v-btn-toggle>
										</v-flex>
									</v-layout>
								</v-container>
							</v-tab-item>

							<!--start: attributes translation-->
							<v-tab-item v-if="this.isTranslationEnabled" key="translation">
								<b-tabs>
									<!-- germany -->
									<b-tab active>
										<template #title>
											<v-btn text class="ma-0">
												<country-flag country="DE" />
											</v-btn>
										</template>

										<v-row no-gutters align="center" justify="center">
											<v-col cols="12" sm="8">
												<v-text-field
													@focus="showTouchKeyboard"
													:data-layout="KEYBOARD.KEYSETS.NORMAL"
													v-model="itemName_de"
													:label="$t('erp.lang_posItemName')"
													autocomplete="off"
													outlined
													dense
												></v-text-field>
											</v-col>

											<v-col cols="12" sm="8">
												<!--                        <v-textarea v-model="itemDescription_de" @focus="showTouchKeyboard"
                                                            :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('generic.lang_description')"
                                                            autocomplete="off" outlined
                                                            dense/>-->
												<label>{{ $t("generic.lang_description") }}</label>
												<quill-editor
													@focus="showTouchKeyboard"
													:data-layout="KEYBOARD.KEYSETS.NORMAL"
													output="html"
													class="mb-4"
													style="height: 150px"
													:options="toolbarOptions"
													v-model="itemDescription_de"
												></quill-editor>
											</v-col>
										</v-row>
									</b-tab>

									<!-- english -->
									<b-tab>
										<template #title>
											<v-btn text class="ma-0">
												<country-flag country="GB" />
											</v-btn>
										</template>

										<v-row no-gutters align="center" justify="center">
											<v-col cols="12" sm="8">
												<v-text-field
													@focus="showTouchKeyboard"
													:data-layout="KEYBOARD.KEYSETS.NORMAL"
													v-model="itemName_en"
													:label="$t('erp.lang_posItemName')"
													autocomplete="off"
													outlined
													dense
												></v-text-field>
											</v-col>

											<v-col cols="12" sm="8">
												<!--                        <v-textarea v-model="itemDescription_en" @focus="showTouchKeyboard"
                                                            :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('generic.lang_description')"
                                                            autocomplete="off" outlined
                                                            dense/>-->
												<label>{{ $t("generic.lang_description") }}</label>
												<quill-editor
													@focus="showTouchKeyboard"
													:data-layout="KEYBOARD.KEYSETS.NORMAL"
													output="html"
													class="mb-4"
													style="height: 150px"
													:options="toolbarOptions"
													v-model="itemDescription_en"
												></quill-editor>
											</v-col>
										</v-row>
									</b-tab>

									<!-- french -->
									<b-tab>
										<template #title>
											<v-btn text class="ma-0">
												<country-flag country="FR" />
											</v-btn>
										</template>

										<v-row no-gutters align="center" justify="center">
											<v-col cols="12" sm="8">
												<v-text-field
													@focus="showTouchKeyboard"
													:data-layout="KEYBOARD.KEYSETS.NORMAL"
													v-model="itemName_fr"
													:label="$t('erp.lang_posItemName')"
													autocomplete="off"
													outlined
													dense
												></v-text-field>
											</v-col>

											<v-col cols="12" sm="8">
												<!--                        <v-textarea v-model="itemDescription_fr" @focus="showTouchKeyboard"
                                                            :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('generic.lang_description')"
                                                            autocomplete="off" outlined
                                                            dense/>-->
												<label>{{ $t("generic.lang_description") }}</label>
												<quill-editor
													@focus="showTouchKeyboard"
													:data-layout="KEYBOARD.KEYSETS.NORMAL"
													output="html"
													class="mb-4"
													style="height: 150px"
													:options="toolbarOptions"
													v-model="itemDescription_fr"
												></quill-editor>
											</v-col>
										</v-row>
									</b-tab>

									<!-- arabic -->
									<b-tab>
										<template #title>
											<v-btn text class="ma-0">
												<country-flag country="SA" />
											</v-btn>
										</template>

										<v-row no-gutters align="center" justify="center">
											<v-col cols="12" sm="8">
												<v-text-field
													@focus="showTouchKeyboard"
													:data-layout="KEYBOARD.KEYSETS.NORMAL"
													v-model="itemName_ar"
													:label="$t('erp.lang_posItemName')"
													autocomplete="off"
													outlined
													dense
												></v-text-field>
											</v-col>

											<v-col cols="12" sm="8">
												<!--                        <v-textarea v-model="itemDescription_ar" @focus="showTouchKeyboard"
                                                            :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('generic.lang_description')"
                                                            autocomplete="off" outlined
                                                            dense/>-->
												<label>{{ $t("generic.lang_description") }}</label>
												<quill-editor
													@focus="showTouchKeyboard"
													:data-layout="KEYBOARD.KEYSETS.NORMAL"
													output="html"
													class="mb-4"
													style="height: 150px"
													:options="toolbarOptions"
													v-model="itemDescription_ar"
												></quill-editor>
											</v-col>
										</v-row>
									</b-tab>
								</b-tabs>
							</v-tab-item>
							<!--end: attributes translation-->

							<v-tab-item key="onlineShop" v-if="this.$store.getters['permissions/checkModule'](48)">
								<!--                use v-model to set the ePagesData like in textfeilds -->
								<epages-component
									v-model="ePagesData"
									:item="{ id: id, salePrice: itemSalePrice, itemGroup: itemGroupNameSelect }"
								></epages-component>
							</v-tab-item>
							<v-tab-item key="wooCommerce" v-if="this.$store.getters['permissions/checkModule'](63) || this.$store.getters['permissions/checkModule'](77)">
								<!--use v-model to set the wooCommerceData like in textfeilds -->
								<woo-commerce-component
									v-model="wooCommerceData"
									:item="{ id: id, salePrice: itemSalePrice, itemGroup: itemGroupNameSelect }"
								></woo-commerce-component>
							</v-tab-item>
							<v-tab-item key="pricesize" v-if="this.$store.getters['permissions/checkModule'](24)">
								<v-container fluid>
									<v-layout>
										<v-flex md12 ml-2 mr-2>
											<v-col class="text-right" cols="12">
												<v-spacer />

												<v-dialog max-width="600px" persistent v-model="pricesize">
													<template v-slot:activator="{ on }">
														<button
															@click="pricesize = true"
															class="btn-shadow d-inline-flex align-items-center btn btn-success pl-1 mx-auto"
															type="button"
															v-on="on"
														>
															<v-icon class="mr-1 white--text pa-0" icon="plus">add </v-icon>
															{{ $t("generic.lang_create") }}
														</button>
													</template>

													<v-card>
														<v-card-title
															:class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
															class="card-header"
														>
															{{ $t("erp.addSizeVariation") }}
														</v-card-title>

														<v-card-text>
															<v-row align-content="center" justify="center">
																<v-col cols="12" md6 ml-2 mr-2 sm="6">
																	<v-text-field
																		:data-layout="KEYBOARD.KEYSETS.NORMAL"
																		:label="$t('erp.lang_SizeVariation')"
																		@focus="showTouchKeyboard"
																		autocomplete="off"
																		outlined
																		required
																		v-model="mealVarAdd"
																	></v-text-field>
																</v-col>
																<v-col cols="12" sm="6">
																	<v-text-field
																		:data-layout="KEYBOARD.KEYSETS.NORMAL"
																		:label="$t('generic.lang_sorting')"
																		@focus="showTouchKeyboard"
																		autocomplete="off"
																		outlined
																		required
																		step="0.01"
																		type="number"
																		v-model="mealVarPosAdd"
																	></v-text-field>
																</v-col>
															</v-row>
														</v-card-text>

														<v-card-actions
															:class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
															class="text-right card-footer"
														>
															<v-spacer />
															<v-btn @click="pricesize = false" color="error"
																>{{ $t("generic.lang_prev") }}
															</v-btn>
															<v-btn
																:disabled="mealVarAdd.length < 1 || this.createPriceSizeLoader"
																:loading="this.createPriceSizeLoader"
																@click="createPriceSize"
																color="success"
																>{{ $t("generic.lang_add") }}
															</v-btn>
														</v-card-actions>
													</v-card>
												</v-dialog>
											</v-col>

											<b-table
												:fields="mealSizesFields"
												:items="mealSizes"
												hover
												ref="priceSizeTable"
												responsive
												striped
												:table-variant="this.$vuetify.theme.dark ? 'dark' : ''"
											>
												<template v-slot:cell(totalprice)="data">
													<v-text-field
														:data-layout="KEYBOARD.KEYSETS.NORMAL"
														@focus="showTouchKeyboard"
														autocomplete="off"
														outlined
														v-model="data.item.totalprice"
													></v-text-field>
												</template>
											</b-table>
										</v-flex>
									</v-layout>
								</v-container>
							</v-tab-item>
							<v-tab-item key="picture">
								<v-container fluid>
									<v-layout>
										<v-flex md12 ml-2 mr-2>
											<image-cropper ref="imageCropper" v-model="productImage" @update="updateImage" />
										</v-flex>
									</v-layout>
								</v-container>
								<ItemAdditionalImagesComponent :images="additionalImages" :itemId="id" @imageUpdated="getData"/>
							</v-tab-item>
							<v-tab-item key="wareattributes">
								<v-container fluid class="mt-4">
									<v-layout row>
										<v-flex md4 ml-2 mr-2 xs12>
											<v-text-field
												:data-layout="KEYBOARD.KEYSETS.NORMAL"
												:label="$t('erp.lang_warecreate_biozertno')"
												@focus="showTouchKeyboard"
												autocomplete="off"
												outlined
												v-model="biozertnr"
											></v-text-field>
										</v-flex>
										<v-flex md4 ml-2 mr-2 xs8>
											<v-text-field
												:data-layout="KEYBOARD.KEYSETS.NORMAL"
												:label="$t('erp.lang_warecreate_biotext')"
												@focus="showTouchKeyboard"
												autocomplete="off"
												outlined
												v-model="BioText"
											></v-text-field>
										</v-flex>

										<v-flex md2 ml-2 mr-2 xs3>
											<v-checkbox
												:label="$t('erp.lang_warecreate_bioware')"
												autocomplete="off"
												:true-value="'1'"
												:false-value="'0'"
												v-model="bio_yes_no"
											></v-checkbox>
										</v-flex>
									</v-layout>
									
									<v-layout>
										<v-flex>
											<label style="font-size: 120%">{{ $t("erp.lang_storageInformations") }}</label>
										</v-flex>
									</v-layout>
									<v-container fluid></v-container>
									<v-layout row>
										<v-flex md4 ml-2 mr-2 xs12>
											<v-text-field
												:data-layout="KEYBOARD.KEYSETS.NORMAL"
												:label="$t('erp.lang_storagePlace')"
												@focus="showTouchKeyboard"
												autocomplete="off"
												outlined
												v-model="storageShelfLocation"
											></v-text-field>
										</v-flex>
									</v-layout>

									<br />
									<hr style="margin: 0px 0px 7px 0px">
									<br />

									<v-layout>
										<v-flex>
											<label style="font-size:120%;">{{ $t("erp.lang_menuAndGarnishExtraType") }}</label>
										</v-flex>
									</v-layout>
									<v-layout wrap>
										<v-flex key="mg-1" md4 xs12>
											<v-checkbox :hide-details="true" :value="1" style="margin-top: 0px !important" v-model="mealSpecialType">
												<template v-slot:label>
													<div class="d-flex">
														<v-img height="30" width="30" src="@/assets/images/menu-type/world-vegetarian-day.png" contain class="mr-2"></v-img>
														<span>{{ $t('erp.lang_vegetarian') }}</span>
													</div>
												</template>
											</v-checkbox>
										</v-flex>

										<v-flex key="mg-2" md4 xs12>
											<v-checkbox :hide-details="true" :value="2" style="margin-top: 0px !important" v-model="mealSpecialType">
												<template v-slot:label>
													<div class="d-flex">
														<v-img height="30" width="30" src="@/assets/images/menu-type/vegan.png" contain class="mr-2"></v-img>
														<span>{{ $t('erp.lang_vegan') }}</span>
													</div>
												</template>
											</v-checkbox>
										</v-flex>

										<v-flex key="mg-3" md4 xs12>
											<v-checkbox :hide-details="true" :value="3" style="margin-top: 0px !important" v-model="mealSpecialType">
												<template v-slot:label>
													<div class="d-flex">
														<v-img height="30" width="30" src="@/assets/images/menu-type/halal.png" contain class="mr-2"></v-img>
														<span>{{ $t('erp.lang_halal') }}</span>
													</div>
												</template>
											</v-checkbox>
										</v-flex>

										<v-flex key="mg-4" md4 xs12>
											<v-checkbox :hide-details="true" :value="4" style="margin-top: 0px !important" v-model="mealSpecialType">
												<template v-slot:label>
													<div class="d-flex">
														<v-img height="30" width="30" src="@/assets/images/menu-type/kosher.png" contain class="mr-2"></v-img>
														<span>{{ $t('erp.lang_kosher') }}</span>
													</div>
												</template>
											</v-checkbox>
										</v-flex>
									</v-layout>

									<br />
									<hr style="margin: 5px 0px">
									<br />

									<v-layout>
										<v-flex>
											<label style="font-size: 120%">{{ $t("erp.lang_mealType") }}</label>
										</v-flex>
									</v-layout>
									<v-layout wrap>
										<v-flex :key="'m-' + index" md4 v-for="(MealType, index) in MealTypes" xs12>
											<v-checkbox
												:hide-details="true"
												:label="MealType.name"
												:value="MealType.uuid"
												autocomplete="off"
												style="margin-top: 0px !important"
												v-model="MealTypesIdSelector"
											></v-checkbox>
										</v-flex>
									</v-layout>

									<br />
									<hr style="margin: 5px 0px">
									<br />

									<v-layout>
										<v-flex>
											<label style="font-size: 120%">{{ $t("erp.lang_Zusatzstoffe") }}</label>
										</v-flex>
									</v-layout>
									<v-layout wrap>
										<v-flex :key="'z-' + index" md4 v-for="(additive, index) in additives" xs12>
											<v-checkbox
												:hide-details="true"
												:label="additive.label"
												autocomplete="off"
												style="margin-top: 0px !important"
												v-model="additives[index].selected"
											></v-checkbox>
										</v-flex>
									</v-layout>

									<br />
									<hr style="margin: 5px 0px">
									<br />

									<v-layout>
										<v-flex>
											<label style="font-size: 120%">{{ $t("erp.lang_Allergene") }}</label>
										</v-flex>
									</v-layout>
									<v-layout wrap>
										<v-flex :key="'a-' + index" md4 v-for="(allergen, index) in allergene" xs12>
											<v-checkbox
												:hide-details="true"
												:label="allergen.label"
												autocomplete="off"
												style="margin-top: 0px !important"
												v-model="allergene[index].selected"
											></v-checkbox>
										</v-flex>
									</v-layout>
								</v-container>
							</v-tab-item>

							<v-tab-item key="extras" v-if="this.$store.getters['permissions/checkModule'](19)">
								<EditItemExtras
									v-model="extras_notes"
									:dont-ask-for-extras-prop="dontAskForExtras"
									:item-i-d="id"
									ref="itemExtras"
								></EditItemExtras>
							</v-tab-item>
							<v-tab-item key="ingredient" v-if="this.$store.getters['permissions/checkModule'](20)">
								<v-card-text class="ma-0 pa-0">
									<IngredientComponent v-bind:ingredients="this.ingredients"></IngredientComponent>
								</v-card-text>
							</v-tab-item>
							<v-tab-item key="fibu">
								<v-container fluid>
									<v-layout>
										<v-flex md12 ml-2 mr-2>
											<p>
												{{ $t("accounting.lang_hint_FibuKontenDescr") }}
											</p>
										</v-flex>
									</v-layout>
									<v-layout row>
										<v-flex md5 ml-2 mr-2 xs12>
											<v-text-field
												:data-layout="KEYBOARD.KEYSETS.NORMAL"
												@focus="showTouchKeyboard"
												autocomplete="off"
												:label="$t('accounting.lang_fibuAccNumber')"
												outlined
												v-model="fibuAccNumber"
											></v-text-field>
										</v-flex>

										<v-flex md5 ml-2 mr-2 xs12>
											<v-text-field
												:data-layout="KEYBOARD.KEYSETS.NORMAL"
												@focus="showTouchKeyboard"
												autocomplete="off"
												:label="$t('accounting.lang_fibuBooKingKeyTax')"
												outlined
												v-model="fibuBooKingKeyTax"
											></v-text-field>
										</v-flex>
									</v-layout>
									<v-layout row>
										<v-flex md5 ml-2 mr-2 xs12>
											<v-text-field
												:data-layout="KEYBOARD.KEYSETS.NORMAL"
												@focus="showTouchKeyboard"
												autocomplete="off"
												:label="$t('accounting.costcentre') + ' 1'"
												outlined
												v-model="costCentre1"
											></v-text-field>
										</v-flex>
										<v-flex md5 ml-2 mr-2 xs12>
											<v-text-field
												:data-layout="KEYBOARD.KEYSETS.NORMAL"
												@focus="showTouchKeyboard"
												autocomplete="off"
												:label="$t('accounting.costcentre') + ' 2'"
												outlined
												v-model="costCentre2"
											></v-text-field>
										</v-flex>
									</v-layout>
								</v-container>
							</v-tab-item>

							<v-tab-item key="additional_barcode" v-if="this.$store.getters['permissions/checkModule'](42)">
								<edit-item-additional-barcode
									:itemName="itemName"
									:item-sell-price="itemSalePrice"
									:item-net-price="netSalePrice"
								/>
							</v-tab-item>

							<v-tab-item
								key="customer_group_prices"
								v-if="this.$store.getters['permissions/checkModule'](0)"
							>
								<item-customer-group-prices
									:original-sell-price="this.itemSalePrice"
									:item-id="this.mainItemId"
									:item-purchase-price="this.itemPurchasePrice"
									:item-tax="this.currentItemTax"
								/>
							</v-tab-item>

							<v-tab-item key="serial_numbers" v-if="this.$store.getters['permissions/checkModule'](53)">
								<item-serial-numbers-component />
							</v-tab-item>

							<v-tab-item key="variations" v-if="this.$store.getters['permissions/checkModule'](33)">
								<EditItemVariations
									:item-i-d="id"
									:item-deposit-select="itemDepositSelect"
									v-bind:old-price="parseFloat(this.itemSalePrice)"
								></EditItemVariations>
							</v-tab-item>

							<v-tab-item key="controlsystem">
								<control-system-component
									v-if="
										(this.variations && this.variations == 0) ||
										this.variations === undefined ||
										this.variations === null
									"
								/>
								<b-card class="shadow-none" no-body v-else>
									<b-tabs card>
										<b-tab active class="pa-0 ma-0" title="Main Item">
											<b-card-text class="pa-0 ma-0">
												<control-system-component />
											</b-card-text>
										</b-tab>
										<b-tab class="pa-0 ma-0" title="Variations">
											<b-card-text>
												<v-expansion-panels>
													<v-expansion-panel :key="i" v-for="(item, i) in this.variations">
														<v-expansion-panel-header
															>{{ item.name }} ({{ item.identcode }})
														</v-expansion-panel-header>
														<v-expansion-panel-content class="pa-0 ma-0">
															<control-system-component v-bind:item-id="item.id" />
														</v-expansion-panel-content>
													</v-expansion-panel>
												</v-expansion-panels>
												<v-divider class="pa-0 ma-0" />
											</b-card-text>
										</b-tab>

										<b-tab class="pa-0 ma-0" title="Whole variations">
											<b-card-text class="pa-0 ma-0">
												<control-system-component v-bind:variations="this.variations.map((x) => x.id)" />
											</b-card-text>
										</b-tab>
									</b-tabs>
								</b-card>
							</v-tab-item>

							<!--start: nutrition-->
							<v-tab-item
								v-if="
									this.$store.getters['permissions/checkModule'](54) ||
									this.$store.getters['permissions/checkModule'](39)
								"
								key="nutrition"
							>
								<nutrition-component v-model="nutritionTable" />
							</v-tab-item>
							<!--end: nutrition-->

							<v-tab-item key="storagelog">
								<ItemStorageLogComponent :ware-id="Number(mainItemId)" />
							</v-tab-item>
						</v-tabs-items>
					</v-tabs>

					<v-container fluid>
						<v-layout>
							<v-flex class="text-right">
								<v-btn
									:disabled="this.editing || this.deleting || this.duplicating"
									v-if="$store.getters['permissions/checkPermission']('ware_delete')"
									:loading="this.deleting"
									@click="deleteData"
									color="error"
									>{{ $t("generic.lang_delete") }}
								</v-btn>
								<v-btn
									:disabled="itemName.length < 1 || this.editing || this.deleting || this.duplicating"
									:loading="this.editing"
									@click="editData"
									color="success"
									>{{ $t("generic.lang_save") }}
								</v-btn>
								<v-btn
									:disabled="this.editing || this.deleting || this.duplicating"
									:loading="this.duplicating"
									@click="dupItem"
									color="primary"
								>
									{{ $t("generic.lang_duplicateCopy") }}
								</v-btn>
								<v-btn
									:disabled="this.editing || this.deleting || this.duplicating"
									@click="goBacktoItem"
									color="error"
									text
									>{{ $t("generic.lang_prev") }}
								</v-btn>
							</v-flex>
						</v-layout>
					</v-container>
				</v-form>
			</div>

			<div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
				<vue-touch-keyboard
					:accept="hideTouchKeyboard"
					:cancel="hideTouchKeyboard"
					:defaultKeySet="touchKeyboard.keySet"
					:input="touchKeyboard.input"
					:layout="touchKeyboard.layout"
					:options="touchKeyboard.options"
					class="internalWidthExpanded"
					id="onScreenKeyboard"
					v-if="touchKeyboard.visible"
				/>
			</div>

			<canvas width="400" height="32" id="translationCanvas"></canvas>
		</div>
	</div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
	faBarcode,
	faCheeseburger,
	faCocktail,
	faConciergeBell,
	faCroissant,
	faFish,
	faFrenchFries,
	faGifts,
	faHamburger,
	faMeat,
	faPersonCarry,
	faPizzaSlice,
	faSalad,
	faShishKebab,
} from "@fortawesome/pro-light-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import EditItemExtras from "./edititem/EditItemExtras";
import EditItemVariations from "./edititem/EditItemVariations";
import mixin from "../../../mixins/KeyboardMixIns";
import ImageCropper from "../../common/imagecropper";
import FontawesomePicker from "../../common/iconPicker";
import ControlSystemComponent from "./edititem/ControlSystemComponent";
import EditItemAdditionalBarcode from "@/components/erp/baseData/edititem/EditItemAdditionalBarcode";
import Buttons from "@/components/pos/Buttons";
import EpagesComponent from "@/components/erp/baseData/edititem/EpagesComponent";
import WooCommerceComponent from "@/components/erp/baseData/edititem/WooCommerceComponent";
import IngredientComponent from "@/components/erp/baseData/edititem/IngredientComponent";
import ItemSerialNumbersComponent from "@/components/erp/baseData/edititem/ItemSerialNumbersComponent";
import { createNamespacedHelpers } from "vuex";
import CountryFlag from "vue-country-flag";
import NutritionComponent from "@/components/erp/baseData/edititem/NutritionComponent";
import ItemAdditionalImagesComponent from "@/components/erp/baseData/edititem/ItemAdditionalImagesComponent";
import ItemCustomerGroupPrices from "@/components/erp/baseData/edititem/ItemCustomerGroupPrices";
import ItemStorageLogComponent from "./edititem/ItemStorageLogComponent";
import validation from "../../../mixins/validation/validation";

library.add(
	faBarcode,
	faCocktail,
	faPersonCarry,
	faMeat,
	faGifts,
	faConciergeBell,
	faHamburger,
	faCheeseburger,
	faFrenchFries,
	faPizzaSlice,
	faShishKebab,
	faFish,
	faCroissant,
	faSalad
);

export default {
	components: {
		ItemStorageLogComponent,
		ItemCustomerGroupPrices,
		ItemSerialNumbersComponent,
		EpagesComponent,
		WooCommerceComponent,
		Buttons,
		EditItemAdditionalBarcode,
		ControlSystemComponent,
		"font-awesome-icon": FontAwesomeIcon,
		ImageCropper,
		FontawesomePicker,
		EditItemExtras,
		EditItemVariations,
		IngredientComponent,
		CountryFlag,
		quillEditor,
		NutritionComponent,
		ItemAdditionalImagesComponent,
	},
	props: {
		itemname: String,
	},
	mixins: [mixin, validation],
	data() {
		return {
			nutritionTable: {
				unit: "",
				calories_kj: "",
				calories_kcal: "",
				fat: "",
				saturated_fat: "",
				carbohydrate: "",
				sugars: "",
				protein: "",
				salt: "",
			},
			toolbarOptions: {
				modules: {
					toolbar: [
						["bold", "italic", "underline", "strike"],
						[{ align: [] }],
						[{ font: [] }],
						[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
					],
				},
			},
			editing: false,
			deleting: false,
			duplicating: false,
			itemName_de: "",
			itemName_en: "",
			itemName_fr: "",
			itemName_ar: "",
			itemDescription_de: "",
			itemDescription_en: "",
			itemDescription_fr: "",
			itemDescription_ar: "",
			extras_notes: {
				ExtrasNote_de: "",
				ExtrasNote_en: "",
				ExtrasNote_fr: "",
				ExtrasNote_ar: "",
			},
			duplicatedEAN: false,
			duplicatedInternID: false,
			loadingCheck: false,
			loadingCheckInternID: false,
			ENDPOINTS,
			pricesize: false,
			createPriceSizeLoader: false,
			itemIsVariation: false,
			mealVarAdd: "",
			mealVarPosAdd: "",
			mealSizesFields: [
				{
					key: "sizevariation",
					label: this.$t("erp.lang_SizeVariation"),
					sortable: true,
					class: "text-left",
				},
				{
					key: "totalprice",
					label: this.$t("erp.lang_newTotalPrice"),
					sortable: false,
					class: "text-center",
				},
			],
			variations: [],
			select: "",
			selectedIcon: "",
			itemEAN: null,
			itemInternID: null,
			itemName: "",
			id: null,
			itemPrepartionTime: null,
			itemDeposit: null,
			itemDepositSelect: [],
			itemProducer: null,
			itemProducerSelect: [],
			itemTax: null,
			itemOrderbonIDSelector: [],
			MealTypes: [],
			MealTypesIdSelector: [],
			mealSpecialType: null,
			itemOrderbonID: 0,
			bookingNoReq: 0,
			bookingNoRegExp: "",
			pagerNoReq: 0,
			itemNotDiscountable: 0,
			priceCannotChange: 0,
			includeInStatistics: 1,
			payOutItem: 0,
			itemIsService: 0,
			includeItemIntoProjectReport: 0,
			openItemNameChange: 0,
			itemIsActive: 1,
			itemTypeChooser: 3,
			itemDescription: "",
			saisonalItem: 0,
			serialNumberActive: 0,
			chargeNoRequired: 0,
			mhdRequired: 0,
			bookingNumberReq: 0,
			openWeightQTYInput: 0,
			openPriceInput: 0,
			itemColorPicker: "",
			itemPurchasePrice: 0.0,
			itemSalePrice: null,
			netSalePrice: null,
			percentageMargin: null,
			itemNameTranslation: null,
			itemOrderingName: null,
			itemTaxSelect: [],
			itemGroupNameSelect: [],
			itemGroupName: null,
			itemDifferentTaxTakeAway: 0,
			itemAdd: "",
			biozertnr: "",
			BioText: "",
			storageShelfLocation: "",
			ageVerification: "0",
			bio_yes_no: 0,
			productImage: null,
			imageUpdate: false,
			additives: [],
			additionalImages: [],
			mealTypes: [],
			allergene: [],
			mealSizes: [],
			ingredients: [],
			units: [],
			fibuAccNumber: "",
			fibuBooKingKeyTax: "",
			costCentre1: "",
			costCentre2: "",
			dontAskForExtras: false,
			loading: false,
			tab: 0,
			unitID: null,
			unitofPurchaseID: null,
			unitValue: null,
			isMenu: false,
			taxRules: [],
			taxTotal: 100,
			validTax: false,
			validTaxMsg: "",
			isDailyGoods: 0,
			loadingIsDailyGoods: false,
			enableWeighting: false,
			enableTara: false,
			tara: null,
			ePagesData: null,
			wooCommerceData: null,
			barcodeWeightPriceMode: 0,
		};
	},

	watch: {
		tab() {
			//console.log(this.tab);
		},
		productImage(val) {
			this.productImage = val;
		},
		itemPurchasePrice(val) {
			let self = this;

			if (self.useNetPriceForItemData) {
				self.calculMargin("net");
			} else {
				self.calculMargin("gross");
			}
		},
	},

	computed: {
		...createNamespacedHelpers("settings").mapGetters(["getSettingValue"]),
		useNetPriceForItemData() {
			return this.getSettingValue("useNetPriceForItemData") === "1";
		},
		currentItemTax() {
			if (this.isIssetArr(this.itemTaxSelect) && this.itemTax > 0) {
				let search = this.itemTaxSelect.find(({ id }) => id === this.itemTax);
				if (this.isValidObject(search)) return Number(search.value);
			}
			return 0;
		},
		isTranslationEnabled() {
			return parseInt(this.getSettingValue("enable_translation")) === 1;
		},
		mainItemId: function () {
			return this.$route.params.id;
		},
		allergeneIDs() {
			let ids = [];

			this.allergene.forEach((allergen) => {
				if (allergen.selected) {
					ids.push(allergen.key);
				}
			});

			return ids;
		},
		additivesIDs() {
			let ids = [];

			this.additives.forEach((additive) => {
				if (additive.selected) {
					ids.push(additive.key);
				}
			});

			return ids;
		},

		filteredUnits() {
			if (!this.units || !this.units.length) {
				return [];
			}
			let filtered = [];
			filtered = this.units.map((unit) => {
				if (!unit && unit.unit_name.length === 0) return;

				if (unit.unit_name.includes("lang_")) unit.unit_name = this.$t("erp." + unit.unit_name);

				return unit;
			});
			return filtered;
		},
	},

	methods: {
		checkNegativity(evt) {
			if (evt.key === "+") {
				evt.preventDefault();
			}
		},
		calculSalePriceWithMargin(evt) {
			if (evt.key === "Tab") {
				return;
			}

			let margin = 0;
			let purchasePrice = this.itemPurchasePrice;
			let percentage = this.percentageMargin;

			if (purchasePrice && purchasePrice.toString().includes(",")) {
				purchasePrice = purchasePrice.replace(",", ".");
			}

			if (percentage && percentage.toString().includes(",")) {
				percentage = percentage.replace(",", ".");
			}

			if (Number(purchasePrice)) {
				if (!this.useNetPriceForItemData) {
					//let taxedPurchasePrice = Number(purchasePrice) + (Number(purchasePrice) * this.currentItemTax / 100);
          margin = (Number(purchasePrice) + ((percentage * Number(purchasePrice)) / 100)) * (1 + (this.currentItemTax / 100));
				} else {
					margin = Number(purchasePrice) + (percentage * Number(purchasePrice)) / 100;
				}
			} else {
				margin = percentage / 100;
			}

			if (this.useNetPriceForItemData) {
				this.netSalePrice = margin;
			} else {
				this.itemSalePrice = margin;
			}
		},
		calculMargin(type) {
			let margin = 100;
			let purchasePrice = this.itemPurchasePrice;
			let salePriceNet = this.netSalePrice;
			let salePriceGross = this.itemSalePrice;

			if (purchasePrice && purchasePrice.toString().includes(",")) {
				purchasePrice = purchasePrice.replace(",", ".");
			}

			if (salePriceNet && salePriceNet.toString().includes(",")) {
				salePriceNet = salePriceNet.replace(",", ".");
			}

			if (salePriceGross && salePriceGross.toString().includes(",")) {
				salePriceGross = salePriceGross.replace(",", ".");
			}

			if (Number(purchasePrice)) {
				if (type === "net") {
					margin = ((Number(salePriceNet) - Number(purchasePrice)) * 100) / Number(purchasePrice);
				}

				if (type === "gross") {
          //let taxedPurchasePrice = Number(purchasePrice) + (Number(purchasePrice) * this.currentItemTax / 100);
          margin = ( ( ( Number(salePriceGross) / (1 + (this.currentItemTax / 100) )) - Number(purchasePrice)) * 100) / Number(purchasePrice);
				}
			}

			this.percentageMargin = margin;
		},
		updateImage() {
			this.imageUpdate = true;
		},
		taxRulesInput(item) {
			let rest = 100;
			if (Number(item.rate) < 0) {
				item.rate = 0;
				return;
			}

			this.taxRules.forEach((rule) => {
				if (item.id !== rule.id) {
					rest -= Number(rule.rate);
				}
			});

			if (Number(item.rate) > rest) {
				item.rate = rest;
			}
		},
		reset(item) {
			this.taxTotal = 0;
			let rest = 100;

			this.validTax = true;
			this.validTaxMsg = this.$t("generic.lang_adjustFieldsTo100");

			this.taxRules.forEach((rule) => {
				if (item.id !== rule.id) {
					rest -= Number(rule.rate);
				}
				this.taxTotal += Number(rule.rate);
			});

			if (!item.rate || item.rate === "" || !Number(item.rate)) {
				item.rate = 0;
			}

			if (this.taxTotal === 100) {
				this.validTax = false;
				this.validTaxMsg = "";
			}
		},
		getMealType() {
			let self = this;
			self.loading = true;
			this.axios
				.post(ENDPOINTS.ERP.MEALTYPE.GET)
				.then((res) => {
					this.MealTypes = res.data.meal_types;

					self.loading = false;
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: self.$t("generic.lang_errorOccurred"),
						color: "error",
					});
					self.loading = false;
				});
		},
		getUnits() {
			this.axios
				.post(ENDPOINTS.ERP.UNITS.GET)
				.then((res) => {
					if (res.data.STATUS === "SUCCESS") {
						this.units = res.data.units;
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred") + this.$t("erp.lang_whileGettingUnits"),
						color: "error",
					});
				});
		},
		checkEAN() {
			this.loadingCheck = true;
			this.axios
				.post(ENDPOINTS.ERP.ITEM.BARCODES.CHECKDUPLICATEEAN, {
					itemId: this.mainItemId,
					barcode: this.itemEAN,
				})
				.then((res) => {
					this.duplicatedEAN = res.data.success;
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: err.message,
						color: "error",
					});
				})
				.finally(() => {
					this.loadingCheck = false;
				});
		},
		checkInternID() {
			this.loadingCheckInternID = true;
			this.axios
				.post(ENDPOINTS.ERP.ITEM.BARCODES.CHECKDUPLICATEINTERNALID, {
					itemId: this.mainItemId,
					internalId: this.itemInternID,
				})
				.then((res) => {
					this.duplicatedInternID = res.data.success;
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: err.message,
						color: "error",
					});
				})
				.finally(() => {
					this.loadingCheckInternID = false;
				});
		},
		loadItemValidation() {
			this.axios
				.post(ENDPOINTS.ERP.ITEM.VARIATIONS.GET, {
					mainItemID: parseInt(this.$route.params.id),
				})
				.then((res) => {
					if (res.data.STATUS === "SUCCESS") {
						this.variations = res.data.variations;
					}
				});
		},
		createPriceSize: function () {
			let self = this;
			self.createPriceSizeLoader = true;
			this.axios
				.post(ENDPOINTS.ERP.SIZEVARIATION.CREATE, {
					addMealVarName: self.mealVarAdd,
					addMealVarSort: self.mealVarPosAdd,
				})
				.then((res) => {
					if (res.data.status === "SUCCESS") {
						Events.$emit("showSnackbar", {
							message: this.$t("erp.lang_sizeVariation_added"),
							color: "success",
						});
						self.mealVarAdd = "";
						self.mealVarPosAdd = 0;
						self.pricesize = false;
						self.getData();
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch(() => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred"),
						color: "error",
					});
				})
				.finally(() => {
					self.createPriceSizeLoader = false;
				});
		},
		goBacktoItem: function () {
			this.$router.push("/erp/baseData/items");
		},
		editData: function () {
			let self = this;

			if (!this.$refs.form.validate()) {
				this.tab = 0;
				this.$vuetify.goTo(0);
				return;
			}

			this.editing = true;

			if (this.$refs.hasOwnProperty("imageCropper")) this.$refs.imageCropper.cropImage();

			let menuTaxRules = {};
			if (this.taxRules && this.taxRules.length > 0) {
				this.taxRules.forEach((rule) => {
					menuTaxRules[rule.id] = rule.rate;
				});
			}
			this.axios
				.post(ENDPOINTS.ERP.EDITITEM.UPDATE, {
					editItemID: this.id,
					ware_ID: this.itemEAN,
					ware_internalNo: this.itemInternID,
					ware_name: this.itemName,
					name_de: this.itemName_de,
					name_en: this.itemName_en,
					name_fr: this.itemName_fr,
					name_ar: this.itemName_ar,
					ware_buyprice: this.itemPurchasePrice,
					wareSellingPrice: this.itemSalePrice,
					netSalePrice: this.netSalePrice,
					editItemWareGroupId: this.itemGroupName,
					editItemTaxId: this.itemTax,
					editItemOrderbonPrinter: this.itemOrderbonID,
					editItemPfandGroupID: this.itemDeposit,
					itemProducerId: this.itemProducer,
					gastroFoodType: this.itemTypeChooser,
					editItemDescr: this.itemDescription,
					editItemZubereitungsZeitInMin: this.itemPrepartionTime,
					editItemPLUColor: this.itemColorPicker,
					radio_ausserhaus_tax: this.itemDifferentTaxTakeAway,
					autoPreiseingabe: this.openPriceInput,
					autoWeightQTYInput: this.openWeightQTYInput,
					itemActive: this.itemIsActive,
					mealSizes: this.mealSizes,
					isService: this.itemIsService,
					includeItemIntoProjectReport: this.includeItemIntoProjectReport,
					mhdRequired: this.mhdRequired,
					serialNumberActive: this.serialNumberActive,
					chargeNoRequired: this.chargeNoRequired,
					allowSetName: this.openItemNameChange,
					auszahlartikel: this.payOutItem,
					bookingNumberReq: this.bookingNoReq,
					bookingNoRegExp: this.bookingNoRegExp,
					pagerNumberReq: this.pagerNoReq,
					itemNotDiscountable: this.itemNotDiscountable,
					priceCannotChange: this.priceCannotChange,
					includeInStatistics: this.includeInStatistics,
					ageVerification: this.ageVerification,
					allergene: this.allergeneIDs,
					meal_types: this.MealTypesIdSelector,
					mealSpecialType: this.mealSpecialType,
					zusaetze: this.additivesIDs,
					productImageUpload: this.imageUpdate ? this.productImage : 0,
					imageUpdate: this.imageUpdate,
					biozertnr: this.biozertnr,
					BioText: this.BioText,
					storageShelfLocation: this.storageShelfLocation,
					bio_yes_no: this.bio_yes_no,
					icon: this.selectedIcon,
					fibuKontonummer: this.fibuAccNumber,
					buchungsschluessel: this.fibuBooKingKeyTax,
					kost1: this.costCentre1,
					kost2: this.costCentre2,
					itemNameTranslation: this.itemNameTranslation,
					itemOrderingNameTranslation: this.itemOrderingName,
					translateNameCanvas: this.convert(this.itemNameTranslation),
					translateOrderingNameCanvas: this.convert(this.itemOrderingName),
					unitID: parseInt(Number(this.unitID)),
					unitofPurchaseID: parseInt(Number(this.unitofPurchaseID)),
					unitValue: parseFloat(this.unitValue),
					Ingredients: this.ingredients,
					deleteImage: this.productImage ? 0 : 1,
					isMenuItem: this.isMenu ? 1 : 0,
					enableTara: this.enableTara,
					enableWeighting: this.enableWeighting,
					tara: this.tara,
					menuTaxRules: menuTaxRules,
					nutritionTable: this.nutritionTable,
					onlineShop: this.ePagesData,
					wooCommerceData: this.wooCommerceData,

					//description translation
					description_de: this.itemDescription_de,
					description_en: this.itemDescription_en,
					description_fr: this.itemDescription_fr,
					description_ar: this.itemDescription_ar,

					ExtrasNote_de: this.extras_notes.ExtrasNote_de,
					ExtrasNote_en: this.extras_notes.ExtrasNote_en,
					ExtrasNote_fr: this.extras_notes.ExtrasNote_fr,
					ExtrasNote_ar: this.extras_notes.ExtrasNote_ar,
					barcodeWeightPriceMode: this.barcodeWeightPriceMode,
				})
				.then((res) => {
					if (res.data.status === "SUCCESS") {
						Events.$emit("showSnackbar", {
							message: this.$t("erp.lang_itemSuccessfulEdited"),
							color: "success",
						});
						this.goBacktoItem();
					} else if (res.data.status === "CHECK ERROR" || res.data.status === "duplicateItem") {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_eanCheckFailed"),
							color: "error",
						});
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch(() => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred"),
						color: "error",
					});
				})
				.finally(() => (this.editing = false));
		},
		convert(text) {
			if (document.getElementById("translationCanvas") != null) {
				let canvas = document.getElementById("translationCanvas");

				let canvasEPOSString = 0;
				if (canvas.getContext) {
					let ctx = canvas.getContext("2d");

					ctx.font = "36px Arial";
					//ctx.textBaseline = "top";

					ctx.clearRect(0, 0, canvas.width, canvas.height);
					//ctx.fillStyle = "black";
					ctx.fillText(text === null ? "" : text, 0, 24);

					let builder = new this.$epson.ePOSBuilder();
					try {
						builder.brightness = 1.0;
						builder.halftone = builder["HALFTONE_ERROR_DIFFUSION"];
						builder.addImage(ctx, 0, 0, canvas.width, canvas.height);
						canvasEPOSString = builder.message.replace(/<.+?>/g, "");
						return canvasEPOSString;
					} catch (e) {
						console.log(e.message);
					}
				}
			}
			return null;
		},
		getData: function () {
			this.loading = true;
			this.mealSizes = [];

			this.id = parseInt(this.$parent.id);
			this.axios
				.post(ENDPOINTS.ERP.EDITITEM.GET, {
					editItemID: this.id,
				})
				.then((res) => {
					if (res.status === 200) {
						this.loading = false;
						res.data.waregroups.forEach((row) => {
							this.itemGroupNameSelect.push({
								name: row[1],
								id: parseInt(row[0]),
							});
						});

						res.data.tax.forEach((row) => {
							this.itemTaxSelect.push({
								name: row[2],
								value: row[1],
								id: parseInt(row[0]),
							});
							if (row[1] > 0) {
								this.taxRules.push({
									name: row[2],
									value: row[1],
									id: parseInt(row[0]),
									rate: 0,
								});
							}
						});

						res.data.pfand.forEach((row) => {
							this.itemDepositSelect.push({
								name: row[1],
								id: parseInt(row[0]),
							});
						});

						res.data.producer.forEach((row) => {
							this.itemProducerSelect.push({
								name: row[1],
								id: parseInt(row[0]),
							});
						});

						res.data.printer.forEach((row) => {
							this.itemOrderbonIDSelector.push({
								name: row[1],
								id: parseInt(row[0]),
							});
						});

						if (res.data.mealSizes && res.data.mealSizes.length > 0) {
							for (let i = 0; i < res.data.mealSizes.length; i++) {
								this.mealSizes.push({
									key: res.data.mealSizes[i][0],
									sizevariation: res.data.mealSizes[i][1],
									totalprice: res.data.mealSizes[i][2],
								});
							}
						}
						let allergensArrChecker =
							res.data.allergensArrChecker &&
							res.data.allergensArrChecker != null &&
							res.data.allergensArrChecker.length > 0
								? res.data.allergensArrChecker
								: [];
						let additivesArrChecker =
							res.data.additivesArrChecker &&
							res.data.additivesArrChecker != null &&
							res.data.additivesArrChecker.length > 0
								? res.data.additivesArrChecker
								: [];

						if (res.data.allergensArr && res.data.allergensArr.length > 0) {
							let total = res.data.allergensArr.length;
							for (let i = 0; i < total; i++) {
								let allergen = res.data.allergensArr[i];
								let index = allergensArrChecker.findIndex((a) => a == allergen[0]);
								let selected = index >= 0 ? true : false;
								allergen = {
									key: allergen[0],
									label: allergen[1],
									selected: selected,
								};
								this.allergene.push(allergen);
							}
						}

						if (res.data.additivesArr && res.data.additivesArr.length > 0) {
							let total = res.data.additivesArr.length;
							for (let i = 0; i < total; i++) {
								let additive = res.data.additivesArr[i];
								let index = additivesArrChecker.findIndex((a) => a == additive[0]);
								let selected = index >= 0 ? true : false;
								additive = {
									key: additive[0],
									label: additive[1],
									selected: selected,
								};
								this.additives.push(additive);
							}
						}

						if (
							res.data.formfillData.textFields.menuTaxRules &&
							res.data.formfillData.checkBoxData.isMenuItem === 1
						) {
							this.taxRules.forEach((rule) => {
								if (Object.keys(res.data.formfillData.textFields.menuTaxRules).includes(rule.id.toString())) {
									rule.rate = res.data.formfillData.textFields.menuTaxRules[rule.id];
								}
							});
						}

						this.MealTypesIdSelector = res.data.formfillData.mealTypes;
						this.mealSpecialType = res.data.formfillData.checkBoxData.mealSpecialType;
						this.itemEAN = res.data.formfillData.textFields.ware_ID;
						this.itemInternID = res.data.formfillData.textFields.ware_internalNo;
						this.itemName = res.data.formfillData.textFields.ware_name;
						this.itemPurchasePrice = res.data.formfillData.textFields.ware_buypriceNet;
						this.itemSalePrice = res.data.formfillData.textFields.ware_sellprice;
						this.netSalePrice = res.data.formfillData.textFields.ware_sellpriceNet;
						this.tara = res.data.formfillData.textFields.tara;
						this.itemGroupName = res.data.formfillData.editItemWareGroupId;
						this.itemTax = res.data.formfillData.editItemTaxId;
						this.itemOrderbonID = parseInt(res.data.formfillData.editItemOrderbonPrinter);
						this.itemDeposit = res.data.formfillData.editItemPfandGroupID;
						this.itemProducer = res.data.formfillData.producer;
						this.itemTypeChooser = res.data.formfillData.gastroFoodType;
						this.itemDescription = res.data.formfillData.textFields.editItemDescr;
						this.itemPrepartionTime = res.data.formfillData.textFields.editItemZubereitungsZeitInMin;
						this.itemColorPicker = res.data.formfillData.itemColor;
						this.itemDifferentTaxTakeAway = res.data.formfillData.ausser_haus_taxing;
						this.openPriceInput = res.data.formfillData.checkBoxData.autoPreiseingabe;
						this.mhdRequired = res.data.formfillData.checkBoxData.mhdRequired;
						this.serialNumberActive = res.data.formfillData.checkBoxData.serialNumberActive;
						this.chargeNoRequired = res.data.formfillData.checkBoxData.chargeNoRequired;
						this.openWeightQTYInput = res.data.formfillData.checkBoxData.autoWeightQTYInput;
						this.itemIsActive = res.data.formfillData.checkBoxData.itemActive;
						this.itemIsService = res.data.formfillData.checkBoxData.isService;
						this.includeItemIntoProjectReport =
							res.data.formfillData.checkBoxData.includeItemIntoProjectReport;
						this.itemIsVariation = res.data.formfillData.checkBoxData.itemIsVariation;
						this.openItemNameChange = res.data.formfillData.checkBoxData.allowSetName;
						this.payOutItem = res.data.formfillData.checkBoxData.auszahlartikel;
						this.bookingNoReq = res.data.formfillData.checkBoxData.bookingNumberReq;
						this.bookingNoRegExp = res.data.formfillData.textFields.bookingNoRegExp;
						(this.pagerNoReq = res.data.formfillData.checkBoxData.pagerNumberReq),
							(this.includeInStatistics = res.data.formfillData.checkBoxData.includeInStatistics);
						this.itemNotDiscountable = res.data.formfillData.checkBoxData.itemNotDiscountable;
						this.priceCannotChange = res.data.formfillData.checkBoxData.priceCannotChange;
						this.ageVerification = res.data.formfillData.checkBoxData.ageVerification.toString();
						this.dontAskForExtras = res.data.formfillData.checkBoxData.dontAskForExtras === 1;
						this.enableTara = res.data.formfillData.checkBoxData.enableTara;
						this.enableWeighting = res.data.formfillData.checkBoxData.enableWeighting;
						this.isMenu = res.data.formfillData.checkBoxData.isMenuItem === 1;
						this.isDailyGoods = res.data.formfillData.checkBoxData.isDailyGoods;
						this.productImage = res.data.imageData[1];
						this.additionalImages = res.data.images || [];
						this.refImage = res.data.imageData[1];
						this.selectedIcon = res.data.formfillData.textFields.icon;
						this.itemOrderingName = res.data.formfillData.textFields.itemOrderingNameTranslation;
						this.itemNameTranslation = res.data.formfillData.textFields.itemNameTranslation;
						this.fibuAccNumber = res.data.formfillData.textFields.fibuKontonummer;
						this.fibuBooKingKeyTax = res.data.formfillData.textFields.buchungsschluessel;
						this.costCentre1 = res.data.formfillData.textFields.kost1;
						this.costCentre2 = res.data.formfillData.textFields.kost2;
						this.unitID = parseInt(Number(res.data.formfillData.unitID));
						this.unitofPurchaseID = parseInt(Number(res.data.formfillData.unitofPurchaseID));
						this.biozertnr = res.data.formfillData.textFields.biozertNo;
						this.BioText = res.data.formfillData.textFields.bioText;
						this.storageShelfLocation = res.data.formfillData.textFields.storageShelfLocation;
						this.bio_yes_no = res.data.formfillData.textFields.isBio;
						this.unitValue = parseFloat(res.data.formfillData.unitValue);
						this.ingredients = res.data.ingredients;
						this.ePagesData = res.data.onlineShop;
						this.wooCommerceData = res.data.wooCommerceData;
						this.barcodeWeightPriceMode = res.data.formfillData.barcodeWeightPriceMode;

						//item name translations
						if (
							res.data.formfillData &&
							res.data.formfillData.textFields &&
							res.data.formfillData.textFields.name_translations
						) {
							this.itemName_de = res.data.formfillData.textFields.name_translations.wareName_de;
							this.itemName_en = res.data.formfillData.textFields.name_translations.wareName_en;
							this.itemName_fr = res.data.formfillData.textFields.name_translations.wareName_fr;
							this.itemName_ar = res.data.formfillData.textFields.name_translations.wareName_ar;
						}

						//item description translations
						if (
							res.data.formfillData &&
							res.data.formfillData.textFields &&
							res.data.formfillData.textFields.description_translations
						) {
							this.itemDescription_de =
								res.data.formfillData.textFields.description_translations.description_de;
							this.itemDescription_en =
								res.data.formfillData.textFields.description_translations.description_en;
							this.itemDescription_fr =
								res.data.formfillData.textFields.description_translations.description_fr;
							this.itemDescription_ar =
								res.data.formfillData.textFields.description_translations.description_ar;
						}

						this.extras_notes.ExtrasNote_de = res.data.formfillData.extrasnote.ExtrasNote_de;
						this.extras_notes.ExtrasNote_fr = res.data.formfillData.extrasnote.ExtrasNote_fr;
						this.extras_notes.ExtrasNote_en = res.data.formfillData.extrasnote.ExtrasNote_en;
						this.extras_notes.ExtrasNote_ar = res.data.formfillData.extrasnote.ExtrasNote_ar;
						this.$emit("input", this.itemName);

						//nutrition
						this.nutritionTable =
							typeof res.data.nutritionTable === "object"
								? { ...res.data.nutritionTable }
								: this.nutritionTable;

						/* PRECHECK REQUIRED EAN AND INTERNAL ID CHECK */
						this.checkEAN();
						this.checkInternID();
					}
				})
				.catch((e) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred"),
						color: "error",
					});
					console.log(e);
				});
		},
		dupItem() {
			this.$swal({
				title: this.$t("erp.lang_itemDup_geader"),
				text: this.$t("erp.lang_duplicateItemText"),
				icon: "warning",
				confirmButtonText: this.$t("generic.lang_duplicateCopy"),
				cancelButtonText: this.$t('generic.lang_cancel'),
				showCancelButton: true,
				showLoaderOnConfirm: true,
				preConfirm: () => {
					this.duplicating = true;

					this.axios
						.post(ENDPOINTS.ERP.ITEM.DUPLICATE, {
							cloneFromId: this.$route.params.id,
						})
						.then((res) => {
							if (res.data.status === "SUCCESS") {
								Events.$emit("showSnackbar", {
									message: this.$t("erp.lang_duplicateSucess"),
									color: "success",
								});
								this.$router
									.push({
										name: "erp.baseData.items.edit",
										params: { id: res.data.itemID },
									})
									.then(() => {
										this.getData();
										this.getUnits();
										this.loadItemValidation();
										this.getMealType();
									});
							} else {
								Events.$emit("showSnackbar", {
									message: this.$t("generic.lang_errorOccurred"),
									color: "error",
								});
							}
						})
						.finally(() => (this.duplicating = false));
				},
				allowOutsideClick: () => !this.$swal.isLoading(),
			});
		},
		deleteData: function (idsToDelete = []) {
			this.$swal({
				title: this.$t("erp.lang_ware_delete_sub"),
				text: this.$t("erp.lang_ware_delete_confirm"),
				icon: "warning",
				cancelButtonText: this.$t('generic.lang_cancel'),
				confirmButtonText: this.$t('generic.lang_delete'),
				showCancelButton: true,
				showLoaderOnConfirm: true,
				preConfirm: () => {
					if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
						idsToDelete = [];
						idsToDelete.push(parseInt(this.id));
					}

					this.deleting = true;

					this.axios
						.post(ENDPOINTS.ERP.ITEM.DELETE, {
							deleteItemID: idsToDelete,
						})
						.then((res) => {
							if (res.data.status === "SUCCESS") {
								Events.$emit("showSnackbar", {
									message: this.$t("erp.lang_itemSuccessfulDeleted"),
									color: "success",
								});

								this.resetData();
								this.goBacktoItem();
							} else {
								Events.$emit("showSnackbar", {
									message: this.$t("generic.lang_errorOccurred"),
									color: "error",
								});
							}
						})
						.finally(() => (this.deleting = false));
				},
				allowOutsideClick: () => !this.$swal.isLoading(),
			});
		},
		GetWareId() {
			this.axios
				.post(ENDPOINTS.ERP.INVENTORYITEM.EAN.CREATE)
				.then((res) => {
					if (res.status === 200) {
						this.itemEAN = res.data && res.data != null && res.data.length > 0 ? res.data[0] : this.itemEAN;
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch(() => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred"),
						color: "error",
					});
				});
		},
		resetData: function () {
			this.id = null;
			this.text = "";
		},
		updateIsDailyGoodsStatus() {
			this.loadingIsDailyGoods = true;
			this.axios
				.post(ENDPOINTS.ERP.DAILYGOODS.UPDATE.ISDAILYGOODS, {
					editItemID: this.id,
					isDailyGoods: this.isDailyGoods,
				})
				.then((res) => {
					if (res.data.status === "SUCCESS") {
						Events.$emit("showSnackbar", {
							message: res.data.message,
							color: "success",
						});
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred") + " : " + (res.data.message || res.data.status),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred") + ": " + err.message,
						color: "error",
					});
				})
				.finally(() => {
					this.loadingIsDailyGoods = false;
				});
		},
		decimalNumbers(evt, val) {
			if (
				isNaN(evt.key) &&
				evt.key !== "." &&
				evt.key !== "," &&
				evt.key !== "-" &&
				evt.key !== "Backspace"
			) {
				evt.preventDefault();
			} else {
				if (
					((evt.key == "-" && val.match(/-/g)) || []).length > 1 ||
					(evt.key == "." && val.indexOf(".") > -1)
				) {
					evt.preventDefault();
				}
			}
		},
	},
	mounted() {
		this.getData();
		this.getUnits();
		this.loadItemValidation();
		this.getMealType();
	},
};
</script>

<style>
.v-expansion-panel-content__wrap {
	padding: 0 !important;
}

.v-expansion-panel::before {
	box-shadow: none !important;
}

.color-swatcher-without-transparency
  .v-color-picker__sliders
  .v-color-picker__alpha {
  visibility: hidden;
}

.color-swatcher-without-transparency
  .v-color-picker__sliders
  .v-color-picker__hue {
  margin-bottom: 0 !important;
}

.color-swatcher-without-transparency
  .v-color-picker__swatch:nth-child(20)
  > .v-color-picker__color:nth-child(3) {
  visibility: hidden;
}
</style>
